import React from "react";
import "./SavedSearch.css";
import SavedSearchComponent from "../../components/SavedSearchComponent/SavedSearchComponent";

function SavedSearch() {
  // const handleClickonRow = (e) => {
  //   console.log(e.target);
  // };

  return (
    <div>
      <div>
        <div className="title">Saved Search</div>
        <div className="sub-title">Resume a previous search</div>
      </div>
      <div className="table">
        <SavedSearchComponent />
      </div>
    </div>
  );
}

export default SavedSearch;
