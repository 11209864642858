import React from 'react'
import './Gauge.css'

function Gauge({ value }) {

    const progress = value > 100 ? 100 : value < 0 ? 0 : value; // Limit value to the range of 0-100



    return (
        <div role="progressbar" aria-valuenow={value} aria-valuemin="0" aria-valuemax="100" style={{ "--value": value }}></div>

    )
}

export default Gauge
