import { Outlet } from 'react-router-dom';
import './PageLayout.css';
import SideNavBar from '../components/SideNavBar/SideNavBar';
import NavBar from '../components/NavBar/NavBar';

const PageLayout = () => {
    return (
        <>
            <div>
                <NavBar />

            </div>
            <div className='page-layout'>
                <div>
                    <SideNavBar />
                </div>
                <div>
                    <Outlet />
                </div>
            </div>
        </>
    )
}

export default PageLayout