// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add_new_component {
    display: flex;
    gap: 80px;
    margin-top: 40px;

}

.add_new_component>* {
    display: flex;
    font-family: 'Light';
    font-size: 15px;
    line-height: 20px;
    color: #151515;
    gap: 10px;
}

.add__new__text {
    color: #253D97
}

.add__user__icon {
    cursor: pointer;
}

.loader-container {
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
    height: 50vh;
    /* Adjust the height as needed */
}

.loader {
    border: 4px solid #f3f3f3;
    /* Light gray border */
    border-top: 4px solid #3498db;
    /* Blue border */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    /* Animation for rotation */
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/Admin/Admin.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAS;IACT,gBAAgB;;AAEpB;;AAEA;IACI,aAAa;IACb,oBAAoB;IACpB,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,SAAS;AACb;;AAEA;IACI;AACJ;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,4BAA4B;IAC5B,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,gCAAgC;AACpC;;AAEA;IACI,yBAAyB;IACzB,sBAAsB;IACtB,6BAA6B;IAC7B,gBAAgB;IAChB,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,kCAAkC;IAClC,2BAA2B;AAC/B;;AAEA;IACI;QACI,uBAAuB;IAC3B;;IAEA;QACI,yBAAyB;IAC7B;AACJ","sourcesContent":[".add_new_component {\n    display: flex;\n    gap: 80px;\n    margin-top: 40px;\n\n}\n\n.add_new_component>* {\n    display: flex;\n    font-family: 'Light';\n    font-size: 15px;\n    line-height: 20px;\n    color: #151515;\n    gap: 10px;\n}\n\n.add__new__text {\n    color: #253D97\n}\n\n.add__user__icon {\n    cursor: pointer;\n}\n\n.loader-container {\n    display: flex;\n    /* flex-direction: column; */\n    justify-content: center;\n    align-items: center;\n    height: 50vh;\n    /* Adjust the height as needed */\n}\n\n.loader {\n    border: 4px solid #f3f3f3;\n    /* Light gray border */\n    border-top: 4px solid #3498db;\n    /* Blue border */\n    border-radius: 50%;\n    width: 40px;\n    height: 40px;\n    animation: spin 1s linear infinite;\n    /* Animation for rotation */\n}\n\n@keyframes spin {\n    0% {\n        transform: rotate(0deg);\n    }\n\n    100% {\n        transform: rotate(360deg);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
