import React from "react";
import "./ErrorPopup.css";

function ErrorPopup({ message, open, onClose }) {
  if (!open) return null;

  return (
    <div className="error-popup">
      <div className="error-message">{message}</div>
      <button className="ok-button" onClick={onClose}>
        OK
      </button>
    </div>
  );
}

export default ErrorPopup;
