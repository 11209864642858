import React from 'react'

function LookalikeHeader(props) {

    const handleBackButton = () => {
        props.onBackButtonClick()
    }

    return (
        <div className="statistics">
            <button onClick={handleBackButton}>

                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 22C8.06204 22 5.29884 20.8555 3.22177 18.7782C1.1435 16.7011 0 13.938 0 11C0 8.06204 1.14451 5.29884 3.22177 3.22177C5.29892 1.1435 8.06204 0 11 0C13.938 0 16.7012 1.14451 18.7782 3.22177C20.8565 5.29892 22 8.06204 22 11C22 13.938 20.8555 16.7012 18.7782 18.7782C16.7011 20.8565 13.938 22 11 22ZM11 0.956172C5.46126 0.956172 0.956172 5.46126 0.956172 11C0.956172 16.5387 5.46126 21.0438 11 21.0438C16.5387 21.0438 21.0438 16.5387 21.0438 11C21.0438 5.46126 16.5387 0.956172 11 0.956172Z" fill="#253D97" />
                    <path d="M13.8696 17.218C13.7474 17.218 13.6253 17.1709 13.5311 17.0778L7.79177 11.3385C7.60457 11.1513 7.60457 10.8487 7.79177 10.6626L13.5311 4.92324C13.7183 4.73604 14.0209 4.73604 14.207 4.92324C14.3942 5.11044 14.3942 5.41309 14.207 5.59919L8.80634 10.9999L14.207 16.4006C14.3942 16.5878 14.3942 16.8905 14.207 17.0766C14.114 17.1707 13.9918 17.218 13.8696 17.218Z" fill="#253D97" />
                </svg>
                <span>back</span>

            </button >
            <span>
                {props.icon}
                <span>{props.title}</span>
            </span>

            <span className="statistics-number">{props.paginationTotal.toLocaleString()}</span>results | Page 1 of {((props.paginationTotal / props.resultsPerPage).toFixed(0))}
        </div>
    )
}

export default LookalikeHeader



