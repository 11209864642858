import React from "react";
import "./SelectBox.css";
import Select from "react-select";

function SelectBoxUpdate({ onChange, value, data, placeholder, key }) {
  const customStyles = {
    control: (base, state) => ({
      ...base,
      color: "#253D97",
      background: "#white",
      minWidth: "127px",
      // match with the menu
      // borderRadius: state.isFocused ? "8px 8px 8px 8px" : 3,
      // Overwrittes the different states of border
      borderRadius: "8px 8px 8px 8px",
      borderColor: state.isFocused ? "#253D97" : "#253D97",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        borderColor: state.isFocused ? "red" : "#253D97",
      },
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: "#253D97",
    }),
    placeholder: (base) => ({
      ...base,
      color: "#253D97",
    }),
  };

  //   const handleSelectChange = (e) => {
  //     props.handleChange({ [props.selectId]: e.value });
  //   };

  return (
    <>
      <Select
        styles={customStyles}
        placeholder={placeholder}
        id={key}
        // defaultValue={colourOptions[0]}
        // isDisabled={isDisabled}
        // isLoading={isLoading}
        isClearable={false}
        // isRtl={isRtl}
        isSearchable={true}
        // name="color"
        options={data}
        // onChange={props.handleChange}
        onChange={onChange}
        value={value}
      />
    </>
  );
}

export default SelectBoxUpdate;
