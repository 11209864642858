import React from 'react'
import './SideNavBar.css'

import { Link } from 'react-router-dom'

import { ReactComponent as SearchIcon } from '../../assets/icons/icon-search.svg';
import { ReactComponent as SavedSearchIcon } from '../../assets/icons/icon-savedsearch.svg';
import { ReactComponent as TrendingIcon } from '../../assets/icons/icon-trending.svg';
import { ReactComponent as DataIcon } from '../../assets/icons/icon-data.svg';
import { ReactComponent as AdminIcon } from '../../assets/icons/icon-admin.svg';

function SideNavBar() {
    return (

        <div className='side-nav-container'>
            <div className="side-nav-item">
                <Link to="/Search" className='side-nav-element'>
                    <SearchIcon className='icon' />
                    <div className='item-text'>
                        Search
                    </div>
                </Link>
            </div>
            <div className="side-nav-item">
                <Link to="/SavedSearch" className='side-nav-element'>
                    <SavedSearchIcon className='icon' />
                    <div className='item-text'>
                        Saved Search
                    </div>
                </Link>
            </div>
            <div className="side-nav-item">
                <Link to="/Trending" className='side-nav-element'>
                    <TrendingIcon className='icon' />
                    <div className='item-text'>
                        Trending
                    </div>
                </Link>
            </div>
            <div className="side-nav-item">
                <Link to="/Data" className='side-nav-element'>
                    <DataIcon className='icon' />
                    <div className='item-text'>
                        Data
                    </div>
                </Link>
            </div>
            <div className="side-nav-item">
                <Link to="Admin" className='side-nav-element'>
                    <AdminIcon className='icon' />
                    <div className='item-text'>
                        Admin
                    </div>
                </Link>
            </div>


        </div>

    )
}

export default SideNavBar
