import React, { useEffect, useState } from "react";
import "./SavedSearchComponent.css";

import { useNavigate } from "react-router-dom";
import styles from "./SavedSearchComponent.module.css";
import DataTable from "../../components/TablePagination/TablePagination";
import Endpoints from "../../components/Api/Endpoints";
import { ReactComponent as DeleteIcon } from "../../assets/icons/icon-delete.svg";
import { ReactComponent as LupaIcon } from "../../assets/icons/icon-lupa.svg";

function SavedSearchComponent() {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [loader, setLoader] = useState(false);

  const handleDelete = (e) => {
    Endpoints.deleteLogs(e.id)
      .then((res) => {
        setRefresh(!refresh);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleIntentSearch = (e) => {
    navigate(`/SearchResults`, {
      state: {
        query: { intent: e.target.innerText },
        params: {
          country: [],
          industry: [],
          city: [],
          job_title: [],
          job_level: [],
          revenue: [],
        },
      },
    });
  };

  const handleTopicSearch = (e) => {
    navigate(`/SearchResults`, {
      state: {
        query: { topic: e.target.innerText },
        params: {
          country: [],
          industry: [],
          city: [],
          job_title: [],
          job_level: [],
          revenue: [],
        },
      },
    });
  };

  const handleGeneralSearch = (e) => {
    navigate(`/SearchResults`, {
      state: {
        query: e.intent ? { intent: e.intent } : { topic: e.topic },
        params: {
          country: [],
          industry: [],
          city: [],
          job_title: [],
          job_level: [],
          revenue: [],
        },
      },
    });
  };

  const initialState = {
    pageSize: 10,
    pageIndex: 0,
  };

  const columns = [
    {
      Header: "Search by Topic",
      accessor: "topic",
    },
    {
      Header: "Search by Intent",
      accessor: "intent",
    },
    {
      Header: "Date Searched",
      accessor: "created_at",
    },
    {
      Header: "Go to Search",
      accessor: "gotoSearch",
    },
    {
      Header: "Actions",
      accessor: "actions",
    },
  ];

  const params = {
    skip: 0,
    size: 10000,
  };

  useEffect(() => {
    setLoader(true);
    Endpoints.searchLogs(params)
      .then((res) => {
        setData(
          res.data.items.map((obj) => ({
            ...obj,
            actions: <DeleteIcon onClick={() => handleDelete(obj)} />,
            created_at: new Date(obj.created_at).toLocaleDateString(),
            intent: (
              <span onClick={(e) => handleIntentSearch(e)}>
                {obj.data.intent}
              </span>
            ),
            topic: (
              <span onClick={(e) => handleTopicSearch(e)}>
                {obj.data.topic}
              </span>
            ),
            gotoSearch: (
              <LupaIcon onClick={() => handleGeneralSearch(obj.data)} />
            ),
          }))
        );
      })
      .catch((err) => {})
      .finally(() => {
        setLoader(false);
      });
  }, [refresh]);

  return (
    <>
      {loader ? (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      ) : (
        <DataTable
          tableStyle={styles.stylestable}
          trStyle={styles.stylesTr}
          tdStyle={styles.stylesTd}
          theadStyle={styles.stylesThead}
          paginationStyle={styles.stylesPagination}
          data={data}
          columns={columns}
          initialState={initialState}
        />
      )}
    </>
  );
}

export default SavedSearchComponent;
