import React from "react";
import "./ModalIntents.css";

import ButtonComponent from "../../Button/ButtonComponent";

function ModalIntents({ open, children, onClose, title }) {
  if (!open) return null;
  return (
    <>
      <div className="modal__overlay" />
      <div className="modal__position">
        <div className="modal__title">{title}</div>
        <div className="line"></div>
        <div className="modal__content">{children}</div>
        {/* {children} */}
        <ButtonComponent onClick={onClose} label="Close"></ButtonComponent>
      </div>
    </>
  );
}

export default ModalIntents;
