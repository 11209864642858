import React, { useState, useEffect } from 'react'
import './NavBar.css'
import { Auth } from 'aws-amplify'
import { Navigate, Link } from 'react-router-dom'

function NavBar() {

    const [user, setUser] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleLogout = async () => {
        await Auth.signOut();
        localStorage.removeItem('user');
        localStorage.removeItem('bearerToken');
        setUser(false);
        window.location.reload();

    };



    useEffect(() => {

        const storedUser = localStorage.getItem('user');
        setUser(JSON.parse(storedUser));

    }, []);



    return (
        <div className="navbar">
            <Link to="/Search" className="">
                <img src="brand/1.png" alt="Logo" className="logo" />
            </Link>

            <ul className="menu">

                <li><a><img src="images/icon-bell.png" alt="" /></a></li>

                <a className='user-name'>

                    {localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).attributes.email : null}

                </a>

                <li className="dropdown">

                    <a><img src="images/icon-user.png" alt="" /></a>
                    <ul className="dropdown-menu">
                        <li><a>{user ? <li>{user.attributes.email.slice(0, 2)}</li> : null}</a></li>
                        <li><a onClick={handleLogout}>Logout</a></li>
                    </ul>
                </li>

            </ul>
        </div>

    )
}

export default NavBar


// {/* <Link to="/Search" className="navbar-logo" >
// <img src="brand/1.png" alt="" />
// </Link>
// <div>
// <Link to="/Search" className="">
//     <div className="">
//         {/* <span className="text-lg font-bold leading-5 text-white">Dashboard</span> */}
//         <img src="images/icon-bell.png" alt="" />
//     </div>
// </Link>
// </div>

// <div>
// <a className='dropdown' onClick={toggleDropdown}> <img src="images/icon-user.png" alt="" /></a>
// {isOpen && (
//     <div className="dropdown-menu">
//         <a onClick={() => { Auth.signOut() }}>LogOut</a>



//     </div>
// )}
// </div> */}