import React, { useState } from 'react'
import { useDropzone } from 'react-dropzone';
import './Data.css'

function Data() {

    const [errorMsg, setErrorMsg] = useState(null);

    const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
        // Disable click and keydown behavior
        noClick: false,
        accept: {
            'mimeTypes': ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
        },
        onDropRejected: (rejectedFiles) => {
            // Handle rejected files here
            setErrorMsg("Please upload only excel files")
            //wait 10 seconds
            setTimeout(() => {
                setErrorMsg(null)
            }, 4000);


            console.log("Please upload only excel files")
        },
        // noKeyboard: true
        // accept: {
        //     'image/png': ['.png'],
        //     'text/html': ['.html', '.htm'],
        // }
    });

    const files = acceptedFiles.map(file => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));


    return (
        <div className='data-page-container'>

            <div className='text-title'>Upload data</div>

            <div className='data-page-sub-text'>
                <div>1. Download excel template</div>
                <div>2. Drag and Drop Below   </div>
            </div>


            <div className='upload-element'>
                <div>
                    <svg width="41" height="35" viewBox="0 0 41 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M24.9688 30.1513V22.6531H29.1531L20.9063 16.0479L12.596 22.6531H16.8438V30.1513" stroke="#5E5E5E" stroke-linecap="round" stroke-linejoin="round" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.8438 34.1489H24.9687V32.512H16.8438V34.1489Z" stroke="#5E5E5E" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="4 4 1000" />
                        <path d="M23.8722 10.4562C25.1665 9.43967 26.7948 8.83398 28.5611 8.83398C32.5895 8.83398 35.8818 11.9803 36.171 15.9671C38.1624 16.6645 39.5941 18.5683 39.5941 20.8134C39.5941 23.6495 38.1421 26.0067 34.5606 26.0067H29.9375" stroke="#5E5E5E" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M12.1306 11.1262C11.1865 10.7047 10.1424 10.4714 9.04391 10.4714C4.82541 10.4714 1.40641 13.9189 1.40641 18.1726C1.40641 22.4255 4.66129 26.0391 8.87897 26.0391H11.1564" stroke="#5E5E5E" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M9.56854 10.3692C10.0016 5.34858 14.1852 1.40918 19.2812 1.40918C22.9269 1.40918 26.1062 3.42512 27.7783 6.41098" stroke="#5E5E5E" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>

                </div>
                <div>UploadFile</div>
                <section className="section-container">
                    <div {...getRootProps({ className: 'container-upload' })}>
                        <input {...getInputProps()} />
                        <p>Drop File to Upload</p>
                    </div>
                    <div>
                        <ul>{files}</ul>
                        <div className='warning'>{errorMsg}</div>
                    </div>

                </section>
                <div className='mid-title'>
                    - or -
                </div>
                <button type="button" onClick={open}>
                    Choose file
                </button>
            </div>


        </div>
    )
}

export default Data
