import api from "./ApiConnection";

export default {
  getUsers: async () =>
    api({
      method: "GET",
      url: "/accounts",
    }),
  createUser: async (data) =>
    api({
      method: "POST",
      url: "/accounts",
      data: data,
    }),
  assignUser: async (user_sub, group_name) =>
    api({
      method: "POST",
      url: `/accounts/${user_sub}/groups/${group_name}`,
    }),
  deleteUser: async (id) =>
    api({
      method: "DELETE",
      url: `/accounts/${id}`,
    }),
  getFilter: async (params) =>
    api({
      method: "GET",
      url: "/leads/search-filters-single",
      params: params,
    }),
  getLeads: async (params, data) =>
    api({
      method: "POST",
      url: "/leads/search",
      params: params,
      data: data,
    }),
  getEmployees: async (params) =>
    api({
      method: "GET",
      url: "/leads/employees",
      params: params,
    }),
  getLookalike: async (params, data) =>
    api({
      method: "POST",
      url: "/leads/look-a-like",
      params: params,
      data: data,
    }),
  searchLogs: async (params) =>
    api({
      method: "GET",
      url: "/search-log",
      params: params,
    }),
    deleteLogs: async (id) =>
    api({
      method: "DELETE",
      url: `/search-log/${id}`,
    })

};
