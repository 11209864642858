// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.side-nav-component {
    direction: flex;
    background-color: yellow;
}

.side-nav-container {

    background-color: var(--dark-blue);
    height: calc(100vh - 56px);
    position: relative;
    color: white;
    min-width: 92px;
    overflow: hidden;
}

.side-nav-item {
    height: 96px;

}

.side-nav-item:hover {
    background-color: var(--light-blue);
}

.icon {
    display: block;
    margin: 0 auto;
    margin-bottom: 10px;
}

.item-text {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
}

.side-nav-element {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-decoration: none;
    color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/SideNavBar/SideNavBar.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,wBAAwB;AAC5B;;AAEA;;IAEI,kCAAkC;IAClC,0BAA0B;IAC1B,kBAAkB;IAClB,YAAY;IACZ,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,YAAY;;AAEhB;;AAEA;IACI,mCAAmC;AACvC;;AAEA;IACI,cAAc;IACd,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,qBAAqB;IACrB,YAAY;AAChB","sourcesContent":[".side-nav-component {\n    direction: flex;\n    background-color: yellow;\n}\n\n.side-nav-container {\n\n    background-color: var(--dark-blue);\n    height: calc(100vh - 56px);\n    position: relative;\n    color: white;\n    min-width: 92px;\n    overflow: hidden;\n}\n\n.side-nav-item {\n    height: 96px;\n\n}\n\n.side-nav-item:hover {\n    background-color: var(--light-blue);\n}\n\n.icon {\n    display: block;\n    margin: 0 auto;\n    margin-bottom: 10px;\n}\n\n.item-text {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    font-size: 12px;\n}\n\n.side-nav-element {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    height: 100%;\n    text-decoration: none;\n    color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
