import React, { useEffect, useState } from "react";
import "./Trending.css";

import TrendingTopicsTable from "../../components/TrendingTopics/TrendingTopicsTable";
import TrendingTopicsByLocation from "../../components/TrendingTopics/TrendingTopicsByLocation";

function Trending() {
  return (
    <div className="trending-page-container">
      <div className="text-title">Trending Topics</div>
      <div className="text-subtitle">Trending topics</div>
      <div className="trending-page-table">
        <TrendingTopicsTable />
      </div>
      <div className="text-title">Trending Topics by Location</div>
      <div className="text-subtitle">Trending topics</div>
      <TrendingTopicsByLocation />
    </div>
  );
}

export default Trending;
