// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes growProgressBar {

    0%,
    33% {
        --pgPercentage: 0;
    }

    100% {
        --pgPercentage: var(--value);
    }
}

@property --pgPercentage {
    syntax: '<number>';
    inherits: false;
    initial-value: 0;
}

div[role="progressbar"] {
    --size: 8rem;
    --fg: #2DB50B;
    --bg: #EFEFEF;
    --pgPercentage: var(--value);
    animation: growProgressBar 1s 1 forwards;
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    display: grid;
    place-items: center;
    background:
        radial-gradient(closest-side, white 80%, transparent 0 99.9%, white 0),
        conic-gradient(var(--fg) calc(var(--pgPercentage) * 1%), var(--bg) 0);
    font-family: "Regular";
    font-size: calc(var(--size) / 5);
    color: black;
}

div[role="progressbar"]::before {
    counter-reset: percentage var(--value);
    content: counter(percentage) '%';
}`, "",{"version":3,"sources":["webpack://./src/components/Gauge/Gauge.css"],"names":[],"mappings":"AAAA;;IAEI;;QAEI,iBAAiB;IACrB;;IAEA;QACI,4BAA4B;IAChC;AACJ;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,aAAa;IACb,4BAA4B;IAC5B,wCAAwC;IACxC,kBAAkB;IAClB,mBAAmB;IACnB,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB;;6EAEyE;IACzE,sBAAsB;IACtB,gCAAgC;IAChC,YAAY;AAChB;;AAEA;IACI,sCAAsC;IACtC,gCAAgC;AACpC","sourcesContent":["@keyframes growProgressBar {\n\n    0%,\n    33% {\n        --pgPercentage: 0;\n    }\n\n    100% {\n        --pgPercentage: var(--value);\n    }\n}\n\n@property --pgPercentage {\n    syntax: '<number>';\n    inherits: false;\n    initial-value: 0;\n}\n\ndiv[role=\"progressbar\"] {\n    --size: 8rem;\n    --fg: #2DB50B;\n    --bg: #EFEFEF;\n    --pgPercentage: var(--value);\n    animation: growProgressBar 1s 1 forwards;\n    width: var(--size);\n    height: var(--size);\n    border-radius: 50%;\n    display: grid;\n    place-items: center;\n    background:\n        radial-gradient(closest-side, white 80%, transparent 0 99.9%, white 0),\n        conic-gradient(var(--fg) calc(var(--pgPercentage) * 1%), var(--bg) 0);\n    font-family: \"Regular\";\n    font-size: calc(var(--size) / 5);\n    color: black;\n}\n\ndiv[role=\"progressbar\"]::before {\n    counter-reset: percentage var(--value);\n    content: counter(percentage) '%';\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
