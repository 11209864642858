import cityData from './countries_cities.json'

export default function getCities(country) {

    const cities = cityData.filter(city => city.country === country)
    return cities.map(city => { return { label: city.city, value: city.city }})


}


