import React from "react";
import "./ModalDecision.css";
import ButtonComponent from "../../Button/ButtonComponent";

function ModalDecision({ open, title, message, onConfirm, onCancel }) {
  if (!open) return null;

  return (
    <div className="dmodal__position">
      <div className="dmodal__title">{title}</div>
      <div className="horizontal-line"></div>
      <div className="dmodal__content">{message}</div>
      <div className="dmodal__buttons">
        <ButtonComponent onClick={onCancel} label="Cancel" />
        <ButtonComponent onClick={onConfirm} label="Confirm" />
      </div>
    </div>
  );
}

export default ModalDecision;
