import React from "react";
import "./SearchButtons.css";

import SelectBox from "../SelectBox/SelectBoxUpdate";
import CapitalizeWords from "../../utils/CapitalizeWords";
import { getIndustryCategories } from "../../utils/IndustryMapper/IndustryMapper";

const uniqueIndustryCategories = [
  ...new Set(getIndustryCategories.map((item) => item.Category)),
];
console.log(uniqueIndustryCategories);

function SearchButtons() {
  return (
    <>
      <SelectBox
      // keys={index}
      // placeholder={CapitalizeWords(field)}
      // selectId={field}
      // handleChange={handleChangeDropdown}
      // data={filtersData[field]}
      />
      <SelectBox
        placeholder={"Industry"}
        data={uniqueIndustryCategories.map((item) => ({
          label: item,
          value: item,
        }))}
      />
    </>
  );
}

export default SearchButtons;
