import  {getIndustryCategories}  from './IndustryMapper'
import industryData from './IndustryMapperData.json'

const getIndustry = (industry) => {
    const industries = industryData.filter(el => el.Category === industry)



    const res = industries.map(industry => { return { label: industry.Sub_category, value: industry.Sub_category }})

    // return unique by label
    return [...new Map(res.map(item => [item.label, item])).values()]
}

export default function getIndustriesMulti(industries) {

    const result = industries.map(element => getIndustry(element));
    return [].concat(...result);  
}




