// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.horizontal-slider {
    width: 116px;
}


.slider-thumb {
    cursor: pointer;
    position: absolute;
    z-index: 100;
    background: #253D97;
    border: 5px solid #253D97;
    border-radius: 100%;
    display: block;
    box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
}

.slider-thumb.active {
    background-color: #253D97;
}

.slider-track {
    position: relative;
    background: #DFDFDF;
}

.horizontal-slider .slider-track {
    top: 0px;
    height: 5px;
}

.horizontal-slider .slider-thumb {
    top: -6px;
    width: 18px;
    outline: none;
    height: 18px;
    line-height: 38px;
}

.slider__container{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
}

.slider-container span{
    margin-right: 30p;
}

.slider__label {
    align-self: center;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 14px;
    color: #575757;

}`, "",{"version":3,"sources":["webpack://./src/components/CustomSlider/CustomSlider.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;;AAGA;IACI,eAAe;IACf,kBAAkB;IAClB,YAAY;IACZ,mBAAmB;IACnB,yBAAyB;IACzB,mBAAmB;IACnB,cAAc;IACd,sCAAsC;AAC1C;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,QAAQ;IACR,WAAW;AACf;;AAEA;IACI,SAAS;IACT,WAAW;IACX,aAAa;IACb,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,2BAA2B;IAC3B,mBAAmB;IACnB,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,kBAAkB;IAClB,eAAe;IACf,cAAc;;AAElB","sourcesContent":[".horizontal-slider {\n    width: 116px;\n}\n\n\n.slider-thumb {\n    cursor: pointer;\n    position: absolute;\n    z-index: 100;\n    background: #253D97;\n    border: 5px solid #253D97;\n    border-radius: 100%;\n    display: block;\n    box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);\n}\n\n.slider-thumb.active {\n    background-color: #253D97;\n}\n\n.slider-track {\n    position: relative;\n    background: #DFDFDF;\n}\n\n.horizontal-slider .slider-track {\n    top: 0px;\n    height: 5px;\n}\n\n.horizontal-slider .slider-thumb {\n    top: -6px;\n    width: 18px;\n    outline: none;\n    height: 18px;\n    line-height: 38px;\n}\n\n.slider__container{\n    display: flex;\n    justify-content: flex-start;\n    align-items: center;\n    margin-bottom: 10px;\n    width: 100%;\n}\n\n.slider-container span{\n    margin-right: 30p;\n}\n\n.slider__label {\n    align-self: center;\n    margin-left: 10px;\n    margin-right: 10px;\n    font-size: 14px;\n    color: #575757;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
