import React, {
  useEffect,
  useState,
  useRef,
  useFocus,
  useContext,
} from "react";
import { useLocation } from "react-router-dom";
import "./SearchResults.css";
import Endpoints from "../../components/Api/Endpoints";

import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

import Filter from "../../components/Filter/Filter";

import SearchCard from "../../components/SearchCard/SearchCard";
import Spinner from "../../components/Spinner/Spinner";

import { ScoreCalculation } from "../../components/ScoreCalculation/ScoreCalculation";
import { ScoreAlgorithm } from "../../utils/ScoreAlgorithm";
import { getDistinctElements } from "../../utils/Distinct";

import { FiltersDataContext } from "../../context/Context";
import { FiltersValues } from "../../utils/FiltersValues";

import CapitalizeWords from "../../utils/CapitalizeWords";
import ResultsHeader from "./ResultsHeader";
import getCities from "../../utils/CityMapper/CityMapper";
import getCitiesMulti from "../../utils/CityMapper/CityMapperMulti";
import getIndustriesMulti from "../../utils/IndustryMapper/IndustryMapperMulti";

import { getIndustryCategories } from "../../utils/IndustryMapper/IndustryMapper";

import { ReactComponent as LookalikeIcon } from "../../assets/icons/icon-lookalike.svg";
import { ReactComponent as CompanyIcon } from "../../assets/icons/icon-employees.svg";
import { ReactComponent as LupaIcon } from "../../assets/icons/icon-lupa.svg";
import { ReactComponent as RemoveIcon } from "../../assets/icons/icon-remove-small.svg";
import PaginationElement from "../../components/PaginationElement/PaginationElement";
import aggregateArray from "../../utils/AggregateArray";
import categoriesToDB from "../../utils/IndustryMapper/IndustryMapperCattoDB.js";

function SearchResults() {
  // const resultsPerPage = 4;

  // state z poprzedniej strony
  const { state } = useLocation();

  const [refreshFlag, setRefreshFlag] = useState(false);
  const [refreshIndustryFlag, setRefreshIndustryFlag] = useState(false);
  const [refreshCityFlag, setRefreshCityFlag] = useState(false);
  const [loader, setLoader] = useState(false);
  const [searchTitleText, setSearchTitleText] = useState("");

  // pagination

  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  // search query initial params

  const [searchQuery, setSearchQuery] = useState(state.query);
  //filters for query
  const [searchParamsData, setSearchParamsData] = useState(state.params);

  // data for filters
  const { filtersData, setFiltersData } = useContext(FiltersDataContext);
  // const [filtersData, setFiltersData] = useState(state.filters);

  // search input states
  //toggle
  // const [selectedOption, setSelectedOption] = useState(searchQuery['intent'] ? 'intent' : 'topic');

  const [selectedOption, setSelectedOption] = useState(
    searchQuery ? (searchQuery["intent"] ? "intent" : "topic") : "intent"
  );

  const [selectedIndustryCategory, setSelectedIndustryCategory] =
    useState(null);

  //text input
  // const [selectedInput, setSelectedInput] = useState(searchQuery['intent'] || searchQuery['topic'] || '');

  // const [selectedInput, setSelectedInput] = useState(
  //   searchQuery
  //     ? searchQuery["intent"]
  //     : searchQuery
  //     ? searchQuery["topic"]
  //     : ""
  // );

  const [selectedInput, setSelectedInput] = useState(
    searchQuery["intent"] || searchQuery["topic"]
  );

  //search type to display in the search bar
  const [searchType, setSearchType] = useState("normal");

  //search params states + data
  const [searchData, setSearchData] = useState(null);
  // main query - get local state from global
  const [searchParamsQuery, setSearchParamsQuery] = useState(searchQuery);

  const [industryCategory, setIndustryCategory] = useState([]);

  const [SubFilterCheck, setSubFilterCheck] = useState([]);

  const getData = (params, data) => {
    Endpoints.getLeads(params, data)
      .then((res) => {
        setSearchData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getEmployees = (params) => {
    Endpoints.getEmployees(params)
      .then((res) => {
        setSearchData(res.data);
        // setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getLookalike = (params, data) => {
    Endpoints.getLookalike(params, data)
      .then((res) => {
        setSearchData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getFiltersData = (params) => {
    Endpoints.getFilter(params)
      .then((res) => {
        const temp = res.data.map((item, index) => {
          return { value: item, label: item };
        });
        setFiltersData((prevData) => {
          return { ...prevData, [params.field]: temp };
        });
      })
      .catch((err) => {
        setFiltersData((prevData) => {
          return { ...prevData, [params.field]: [] };
        });
        console.log(err);
      });
  };
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const handleCheckboxChange = (e) => {
    // refresh for cities change
    console.log(e.value);
    if (e.filtergroup === "country") {
      setRefreshFlag(!refreshFlag);
    }

    if (e.checked) {
      setSearchParamsData({
        ...searchParamsData,
        [e.filtergroup]: [...searchParamsData[e.filtergroup], e.value],
      });
    } else {
      setSearchParamsData({
        ...searchParamsData,
        [e.filtergroup]: searchParamsData[e.filtergroup].filter(
          (item) => item !== e.value
        ),
      });
    }

    console.log(
      categoriesToDB(searchParamsData.industry).map((item) => item.Database)
    );
    console.log(searchParamsData);
  };

  const hangleSelectSubIndustryCategory = (e) => {
    if (e.checked) {
      setSubFilterCheck([...SubFilterCheck, e.value]);
      const valuesForIndustrySearchQuery = categoriesToDB(SubFilterCheck).map(
        (item) => item.Database
      );
      setSearchParamsData({
        ...searchParamsData,
        industry: valuesForIndustrySearchQuery,
      });
    } else {
      setSubFilterCheck(SubFilterCheck.filter((item) => item !== e.value));
      const valuesForIndustrySearchQuery = categoriesToDB(SubFilterCheck).map(
        (item) => item.Database
      );
      setSearchParamsData({
        ...searchParamsData,
        industry: valuesForIndustrySearchQuery,
      });
    }

    const valuesForIndustrySearchQuery = categoriesToDB(SubFilterCheck).map(
      (item) => item.Database
    );

    console.log(valuesForIndustrySearchQuery);
    console.log(searchParamsData);
  };

  const handleSelectIndustryCategory = (e) => {
    if (e.checked) {
      setIndustryCategory(getDistinctElements([...industryCategory, e.value]));
    } else {
      setIndustryCategory(industryCategory.filter((item) => item !== e.value));
    }
    setRefreshIndustryFlag(!refreshIndustryFlag);
  };

  const handleToggleRadio = () => {
    setSelectedOption(selectedOption === "intent" ? "topic" : "intent");
  };

  const cleanInput = () => {
    setSelectedInput("");
  };

  const handleInputChange = (e) => {
    setSelectedInput(e.target.value);
  };

  //// handle searches ////

  const handleSearch = () => {
    if (selectedInput === "") {
      alert("Please enter search query");
    } else {
      // do poprawki

      try {
        if (searchParamsQuery.hasOwnProperty("topic")) {
          delete searchParamsQuery.topic;
        } else if (searchParamsQuery.hasOwnProperty("intent")) {
          delete searchParamsQuery.intent;
        }
      } catch (err) {
        setSearchParamsQuery({
          ...searchParamsQuery,
          [selectedOption]: selectedInput,
        });
      }
      setSearchParamsQuery({
        ...searchParamsQuery,
        [selectedOption]: selectedInput,
      });
    }
  };

  const handleCompanySearch = (p) => {
    setSearchType("company");
    setSearchTitleText(p.company);
    getEmployees({ company_name: p.company, skip: 0, size: 5 });
  };

  const handleLookaLike = (p) => {
    setLoader(true);
    setSearchType("look-a-like");
    setSearchTitleText(p.full_name);
    getLookalike(
      { skip: 0, size: 10 },
      {
        country: p.country,
        industry: p.industry,
        job_title: p.job_title,
        job_level: p.job_level,
      }
    );
    setTimeout(() => {
      setLoader(false);
    }, 1000);
  };

  const handleBackButton = () => {
    setRefreshFlag(!refreshFlag);
    setSearchType("normal");
  };

  ////////////////////////// handle pagination //////////////////////////

  const handlePageChange = (page) => {
    setCurrentPage((page.target.value - 1) * itemsPerPage);
  };

  const handlePageDecrease = (page) => {
    currentPage > 10
      ? setCurrentPage((prevCount) => prevCount - itemsPerPage)
      : setCurrentPage(0);
  };

  const handlePageIncrease = (page) => {
    setCurrentPage((prevCount) => prevCount + itemsPerPage);
  };

  ////////////////////////// handle pagination //////////////////////////

  const uniqueIndustryCategories = [
    ...new Set(getIndustryCategories.map((item) => item.Category)),
  ];

  const getSubCategories = (value) => {
    const subCategories = getIndustryCategories
      .filter((item) => item.Category === value)
      .map((item) => {
        return { label: item.Sub_category, value: item.Database };
      });

    // const subCategoriesAggregated = aggregateArray(subCategories).map(
    //   (item) => {
    //     return { label: item.label, value: item.value };
    //   }
    // );
    // return subCategoriesAggregated;
    return subCategories;
  };

  useEffect(() => {
    // get filters data if refreshed and no access to the context
    const valuesToReturn = 1000;
    ["country", "industry", "job_title", "job_level"].forEach((item) => {
      getFiltersData({ field: item, size: valuesToReturn });
    });

    getData(
      {
        ...searchParamsQuery,
        ...{
          skip: currentPage,
          size: itemsPerPage,
        },
      },
      searchParamsData
    );
  }, [searchParamsQuery, searchParamsData, refreshFlag, currentPage]);

  return (
    <div className="search-results-page-layout">
      <div className="left-content">
        <div className="text-title">Search Intents</div>
        <div className="text-subtitle">
          Intents are a users explicit interest in a product/service
        </div>
        <div className="text-filters">Refine results</div>
        <div className="horizontal-line"></div>

        {filtersData ? (
          <div>
            <Filter
              name="Country"
              data={filtersData["country"]}
              filtergroup="country"
              onCheckboxChange={handleCheckboxChange}
              visible={true}
              checked={searchParamsData["country"]}
            />
            <Filter
              key={refreshFlag}
              name="City"
              data={
                searchParamsData.country
                  ? getCitiesMulti(searchParamsData.country)
                  : []
              }
              filtergroup="city"
              onCheckboxChange={handleCheckboxChange}
              visible={true}
              checked={searchParamsData["city"]}
            />
            <Filter
              name="Industry Category"
              data={uniqueIndustryCategories.map((item) => ({
                label: item,
                value: item,
              }))}
              filtergroup="industryCategory"
              onCheckboxChange={handleSelectIndustryCategory}
              visible={true}
              checked={industryCategory}
            />
            <Filter
              key={refreshIndustryFlag ? "refresh" : "no-refresh"}
              name="Industry"
              data={getIndustriesMulti(industryCategory)}
              filtergroup="industry"
              onCheckboxChange={hangleSelectSubIndustryCategory}
              visible={true}
              checked={SubFilterCheck}
            />
            <Filter
              name="Job Title"
              data={filtersData["job_title"]}
              filtergroup="job_title"
              onCheckboxChange={handleCheckboxChange}
              visible={true}
              checked={searchParamsData["job_title"]}
            />
            <Filter
              name="Job Level"
              data={filtersData["job_level"]}
              filtergroup="job_level"
              onCheckboxChange={handleCheckboxChange}
              visible={true}
              checked={searchParamsData["job_level"]}
            />
          </div>
        ) : (
          <div class="loader-container">
            <div class="loader"></div>
          </div>
        )}
      </div>

      <div className="sr-page-main-content">
        <div className="sr-search-element">
          <div className="sr-card-container-search">
            <div className="search-result-input-element-full">
              <LupaIcon />
              <InputText
                className="search-result-input-element-item-search"
                value={selectedInput}
                onChange={handleInputChange}
              />
              <RemoveIcon
                onClick={cleanInput}
                className="search-result-input-element-icon"
              />
            </div>
          </div>
          <div className="vertical-line" />

          <div className="right-card">
            <div className="radio">
              <label>
                <input
                  type="radio"
                  value="intent"
                  checked={selectedOption === "intent"}
                  onChange={handleToggleRadio}
                />
                <span>By Intent</span>
              </label>
            </div>
            <div className="radio">
              <div>
                <input
                  type="radio"
                  value="topics"
                  checked={selectedOption === "topic"}
                  onChange={handleToggleRadio}
                />
                By Topics
              </div>
            </div>

            <div>
              <Button
                className="searchBottom btn-animation"
                label="Search"
                onClick={handleSearch}
              />
            </div>
          </div>
        </div>
        <div className="newline-horizontal" />

        {searchData && searchType == "company" ? (
          <ResultsHeader
            title={`Employees of  "${CapitalizeWords(searchTitleText)}"`}
            paginationTotal={searchData.pagination.total}
            resultsPerPage={itemsPerPage}
            icon={<CompanyIcon />}
            onBackButtonClick={handleBackButton}
          />
        ) : null}

        {searchData && searchType == "look-a-like" ? (
          <ResultsHeader
            title={`Look-a-likes for "${CapitalizeWords(searchTitleText)}"`}
            paginationTotal={searchData.pagination.total}
            resultsPerPage={itemsPerPage}
            icon={<LookalikeIcon />}
            onBackButtonClick={handleBackButton}
          />
        ) : null}

        {searchData && searchType == "normal" ? (
          <div className="statistics">
            <span className="statistics-number">
              {searchData.pagination.total.toLocaleString()}
            </span>
            results | Page {currentPage / itemsPerPage + 1} of{" "}
            {(searchData.pagination.total / itemsPerPage).toFixed(0)}
          </div>
        ) : null}

        {searchData ? (
          <div className="search-cards-content">
            {searchData.data.map((item, index) => {
              return (
                <SearchCard
                  key={index}
                  full_name={item._source.full_name}
                  job_title={item._source.job_title}
                  job_role={item._source.job_title_role}
                  email={
                    item._source.emails ? item._source.emails[0].address : null
                  }
                  industry={item._source.industry}
                  country={item._source.location_country}
                  size={item._source.job_company_size}
                  revenue={item._source.revenue}
                  intents={
                    item._source.intent
                      ? getDistinctElements(
                          item._source.intent.map((el) => el.sub_category)
                        )
                      : null
                  }
                  topics={
                    item._source.intent
                      ? getDistinctElements(
                          item._source.intent.map((el) => el.topic)
                        )
                      : null
                  }
                  company={item._source.job_company_name}
                  // score={ScoreCalculation((item._source.intent.map((el) => el._additional.distance)) * 100)}
                  // score={item._source.intent.map((el) => el._additional.distance)}
                  // score={item._score}
                  score={ScoreAlgorithm(item._score)}
                  onClickCompany={handleCompanySearch}
                  onClickLookaLike={handleLookaLike}
                />
              );
            })}
            <PaginationElement
              itemsPerPage={itemsPerPage}
              totalItems={searchData ? searchData.pagination.total : 0}
              currentPage={currentPage}
              onPageChange={handlePageChange}
              onPageIncrease={handlePageIncrease}
              onPageDecrease={handlePageDecrease}
            />
          </div>
        ) : (
          <div class="loader-container">
            <div class="loader"></div>
          </div>
        )}

        {loader ? <Spinner /> : null}
      </div>
    </div>
  );
}

export default SearchResults;
