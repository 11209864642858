import React, { useState } from "react";
import Select from "react-select";
import "./EditUser.css";

function EditUser(props) {
  const [choice, setChoice] = useState("admin");

  const options = [
    { value: "admin", label: "admin" },
    { value: "user", label: "user" },
  ];

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontFamily: "Light",
      //   fontSize: 14,
    }),
  };
  const handleChoice = (e) => {
    setChoice(e.value);
    console.log(e.value);
  };

  const handleClose = () => {
    props.onClose();
  };

  const handleConfirm = () => {
    props.onConfirm(choice);
  };

  return (
    <div className="modal-container">
      <div className="modal-content">
        <div className="modal-title">
          <div>Assign User</div>
          <div></div>
          <div className="horizontal-line-modal " />
        </div>
        <div className="modal-input">
          <div className="modal-text">User Group</div>
          <Select
            placeholder={choice}
            value={choice}
            options={options}
            styles={customStyles}
            onChange={handleChoice}
          />
        </div>
        <div></div>
        <div className="modal-buttons">
          <button onClick={handleClose}>Close</button>
          <button onClick={handleConfirm}>Assign</button>
        </div>
      </div>
    </div>
  );
}

export default EditUser;
