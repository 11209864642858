import ExternalApiConnector from "./ExternalApiConnector";

export default {
    getIndustries: async () =>
      ExternalApiConnector({
        method: "GET",
        url: "/trends/available-industries",
      }),
    getTopIntents: async (obj) => 
    ExternalApiConnector({
        method: "GET",
        url: `/intents`,
        params: obj
    }),
    getCategories: async (sub_category) =>
      ExternalApiConnector({
          method: "GET",
          url: `/trends/categories?sub_category=${encodeURIComponent(sub_category)}`,
      }),
    }