// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table {
    padding: 30px;
}

.title {
    font-family: 'Light';
    font-size: 26px;
    line-height: 35px;
    color: #1B1B1B;
    margin-left: 32px;
    margin-top: 24px;
}

.sub-title {
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #5E5E5E;
    margin-left: 32px;
    margin-top: 9px;
}`, "",{"version":3,"sources":["webpack://./src/pages/SavedSearch/SavedSearch.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,oBAAoB;IACpB,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,iBAAiB;IACjB,eAAe;AACnB","sourcesContent":[".table {\n    padding: 30px;\n}\n\n.title {\n    font-family: 'Light';\n    font-size: 26px;\n    line-height: 35px;\n    color: #1B1B1B;\n    margin-left: 32px;\n    margin-top: 24px;\n}\n\n.sub-title {\n    font-weight: 400;\n    font-size: 13px;\n    line-height: 18px;\n    color: #5E5E5E;\n    margin-left: 32px;\n    margin-top: 9px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
