// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.percentage_change__container {
    display: flex;
    direction: row;
    align-items: center;
    /* justify-content: center; */
    gap: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/PercentageChange/PercentageChange.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,cAAc;IACd,mBAAmB;IACnB,6BAA6B;IAC7B,QAAQ;AACZ","sourcesContent":[".percentage_change__container {\n    display: flex;\n    direction: row;\n    align-items: center;\n    /* justify-content: center; */\n    gap: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
