import React from "react";
import "./PaginationElement.css";

import { ReactComponent as ArrowIcon } from "../../assets/icons/icon-v-arrow.svg";

function PaginationElement(props) {
  const generateRange = (start, end) => {
    // If start is greater than end, return an array with one element - error handler for pagination
    if (start > end) return Array(1);

    return Array(end - start + 1)
      .fill()
      .map((_, idx) => start + idx);
  };

  const pages = Math.ceil(props.totalItems / props.itemsPerPage);
  const displayPages =
    pages > 5 ? generateRange(1, 5) : generateRange(1, pages - 1);

  return (
    <div className="pagination-element">
      {/* {props.itemsPerPage} {props.totalItems} {props.currentPage} */}
      <button
        className="pagination-element-arrows"
        value={props.currentPage}
        onClick={(e) => props.onPageDecrease(e)}
      >
        <ArrowIcon />
      </button>
      {displayPages.map((item, index) => {
        return (
          <button value={item} onClick={(e) => props.onPageChange(e)}>
            {item}
          </button>
        );
      })}
      {props.currentPage > 50 ? (
        <span>
          ... &nbsp;
          <button
            value={props.currentPage / props.itemsPerPage}
            onClick={(e) => props.onPageChange(e)}
          >
            {props.currentPage / props.itemsPerPage}
          </button>
        </span>
      ) : null}
      <button
        className="pagination-element-arrows"
        value={props.currentPage}
        onClick={(e) => props.onPageIncrease(e)}
      >
        <ArrowIcon style={{ transform: "rotate(180deg)" }} />
      </button>
      <span>
        {props.currentPage + 1}-{props.currentPage + props.itemsPerPage} of{" "}
        {Math.ceil(props.totalItems / props.itemsPerPage)}
      </span>
    </div>
  );
}

export default PaginationElement;
