import React, { useState } from "react";
import { Auth } from "aws-amplify";
import "./ForgetPassword.css";

function ForgetPassword(props) {
  const [resendMail, setResendMail] = useState(false);

  const handleBack = () => {
    props.onBack();
  };

  const handleResend = (e) => {
    e.preventDefault();
    props.onResendMail(resendMail);
    Auth.forgotPassword(resendMail)
      .then((data) => console.log(data))
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <div className="forget-container">
        <img src="brand/1.png" alt="Logo" className="logo" />
        <div className="forget-container-items">
          <div className="forget-title">Can't log in?</div>
          <div className="forget-text">
            We'll send a recovery password to your e-mail
          </div>
          <input
            onChange={(e) => setResendMail(e.target.value)}
            type="text"
            placeholder="E-mail"
          />
          <button onClick={handleResend}>Send new e-mail</button>
          <div className="h-line" />
          <button className="end-buttons" onClick={handleBack}>
            Return to log in
          </button>
        </div>
      </div>
    </div>
  );
}

export default ForgetPassword;
