import ReactSlider from "react-slider";
import "./CustomSlider.css";

const CustomSlider = (props) => {
  return (
    <div className="slider__container">
  <label className="slider__label">{props.placeholder}</label>
    <ReactSlider
      className="horizontal-slider"
      thumbClassName="slider-thumb"
      trackClassName="slider-track"
    />

</div>
  );
};
export default CustomSlider;
