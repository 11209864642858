export const industryData = [
    {
        "Category": "Agriculture",
        "Sub_category": "Crops"
    },
    {
        "Category": "Agriculture",
        "Sub_category": "Forestry"
    },
    {
        "Category": "Agriculture",
        "Sub_category": "Animals & Livestock"
    },
    {
        "Category": "Business Services",
        "Sub_category": "HR & Staffing"
    },
    {
        "Category": "Business Services",
        "Sub_category": "Security Products & Services"
    },
    {
        "Category": "Business Services",
        "Sub_category": "Debt Collection"
    },
    {
        "Category": "Business Services",
        "Sub_category": "Management Consulting"
    },
    {
        "Category": "Business Services",
        "Sub_category": "Research & Development"
    },
    {
        "Category": "Business Services",
        "Sub_category": "Information & Document Management"
    },
    {
        "Category": "Business Services",
        "Sub_category": "Commercial Printing"
    },
    {
        "Category": "Business Services",
        "Sub_category": "Facilities Management & Commercial Cleaning"
    },
    {
        "Category": "Business Services",
        "Sub_category": "Food Service"
    },
    {
        "Category": "Business Services",
        "Sub_category": "Advertising & Marketing"
    },
    {
        "Category": "Business Services",
        "Sub_category": "Translation & Linguistic Services"
    },
    {
        "Category": "Business Services",
        "Sub_category": "Accounting Services"
    },
    {
        "Category": "Business Services",
        "Sub_category": "Multimedia & Graphic Design"
    },
    {
        "Category": "Business Services",
        "Sub_category": "Custom Software & IT Services"
    },
    {
        "Category": "Business Services",
        "Sub_category": "Call Centers & Business Centers"
    },
    {
        "Category": "Business Services",
        "Sub_category": "Chambers of Commerce"
    },
    {
        "Category": "Construction",
        "Sub_category": "Civil Engineering Construction"
    },
    {
        "Category": "Construction",
        "Sub_category": "Architecture, Engineering & Design"
    },
    {
        "Category": "Construction",
        "Sub_category": "Commercial & Residential Construction"
    },
    {
        "Category": "Consumer Services",
        "Sub_category": "Car & Truck Rental"
    },
    {
        "Category": "Consumer Services",
        "Sub_category": "Weight & Health Management"
    },
    {
        "Category": "Consumer Services",
        "Sub_category": "Barber Shops & Beauty Salons"
    },
    {
        "Category": "Consumer Services",
        "Sub_category": "Funeral Homes & Funeral Related Services"
    },
    {
        "Category": "Consumer Services",
        "Sub_category": "Landscape Services"
    },
    {
        "Category": "Consumer Services",
        "Sub_category": "Cleaning Services"
    },
    {
        "Category": "Consumer Services",
        "Sub_category": "Childcare"
    },
    {
        "Category": "Consumer Services",
        "Sub_category": "Repair Services"
    },
    {
        "Category": "Consumer Services",
        "Sub_category": "Automotive Service & Collision Repair"
    },
    {
        "Category": "Consumer Services",
        "Sub_category": "Photography Studio"
    },
    {
        "Category": "Education",
        "Sub_category": "Colleges & Universities"
    },
    {
        "Category": "Education",
        "Sub_category": "K-12 Schools"
    },
    {
        "Category": "Education",
        "Sub_category": "Training"
    },
    {
        "Category": "Energy, Utilities & Waste",
        "Sub_category": "Water Treatment"
    },
    {
        "Category": "Energy, Utilities & Waste",
        "Sub_category": "Electricity, Oil & Gas"
    },
    {
        "Category": "Energy, Utilities & Waste",
        "Sub_category": "Waste Treatment, Environmental Services & Recycling"
    },
    {
        "Category": "Energy, Utilities & Waste",
        "Sub_category": "Oil & Gas Exploration & Services"
    },
    {
        "Category": "Finance",
        "Sub_category": "Banking"
    },
    {
        "Category": "Finance",
        "Sub_category": "Venture Capital & Private Equity"
    },
    {
        "Category": "Finance",
        "Sub_category": "Credit Cards & Transaction Processing"
    },
    {
        "Category": "Finance",
        "Sub_category": "Lending & Brokerage"
    },
    {
        "Category": "Finance",
        "Sub_category": "Investment Banking"
    },
    {
        "Category": "Government",
        "Sub_category": "Local"
    },
    {
        "Category": "Government",
        "Sub_category": "Federal"
    },
    {
        "Category": "Government",
        "Sub_category": "State"
    },
    {
        "Category": "Government",
        "Sub_category": "Tribal Nations"
    },
    {
        "Category": "Healthcare Services",
        "Sub_category": "Veterinary Services"
    },
    {
        "Category": "Healthcare Services",
        "Sub_category": "Elderly Care Services"
    },
    {
        "Category": "Healthcare Services",
        "Sub_category": "Blood & Organ Banks"
    },
    {
        "Category": "Healthcare Services",
        "Sub_category": "Mental Health & Rehabilitation Facilities"
    },
    {
        "Category": "Healthcare Services",
        "Sub_category": "Ambulance Services"
    },
    {
        "Category": "Healthcare Services",
        "Sub_category": "Medical Laboratories & Imaging Centers"
    },
    {
        "Category": "Holding Companies & Conglomerates",
        "Sub_category": "Holding Companies & Conglomerates"
    },
    {
        "Category": "Hospitality",
        "Sub_category": "Zoos & National Parks"
    },
    {
        "Category": "Hospitality",
        "Sub_category": "Fitness & Dance Facilities"
    },
    {
        "Category": "Hospitality",
        "Sub_category": "Museums & Art Galleries"
    },
    {
        "Category": "Hospitality",
        "Sub_category": "Libraries"
    },
    {
        "Category": "Hospitality",
        "Sub_category": "Sports Teams & Leagues"
    },
    {
        "Category": "Hospitality",
        "Sub_category": "Restaurants"
    },
    {
        "Category": "Hospitality",
        "Sub_category": "Movie Theaters"
    },
    {
        "Category": "Hospitality",
        "Sub_category": "Performing Arts Theaters"
    },
    {
        "Category": "Hospitality",
        "Sub_category": "Cultural & Informational Centers"
    },
    {
        "Category": "Hospitality",
        "Sub_category": "Amusement Parks, Arcades & Attractions"
    },
    {
        "Category": "Hospitality",
        "Sub_category": "Gambling & Gaming"
    },
    {
        "Category": "Hospitality",
        "Sub_category": "Lodging & Resorts"
    },
    {
        "Category": "Hospitality",
        "Sub_category": "Travel Agencies & Services"
    },
    {
        "Category": "Hospitals & Physicians Clinics",
        "Sub_category": "Physicians Clinics"
    },
    {
        "Category": "Hospitals & Physicians Clinics",
        "Sub_category": "Medical Specialists"
    },
    {
        "Category": "Hospitals & Physicians Clinics",
        "Sub_category": "Medical & Surgical Hospitals"
    },
    {
        "Category": "Hospitals & Physicians Clinics",
        "Sub_category": "Dental Offices"
    },
    {
        "Category": "Insurance",
        "Sub_category": "Insurance"
    },
    {
        "Category": "Law Firms & Legal Services",
        "Sub_category": "Law Firms & Legal Services"
    },
    {
        "Category": "Manufacturing",
        "Sub_category": "Textiles & Apparel"
    },
    {
        "Category": "Manufacturing",
        "Sub_category": "Motor Vehicles"
    },
    {
        "Category": "Manufacturing",
        "Sub_category": "Industrial Machinery & Equipment"
    },
    {
        "Category": "Manufacturing",
        "Sub_category": "Test & Measurement Equipment"
    },
    {
        "Category": "Manufacturing",
        "Sub_category": "Computer Equipment & Peripherals"
    },
    {
        "Category": "Manufacturing",
        "Sub_category": "Tires & Rubber"
    },
    {
        "Category": "Manufacturing",
        "Sub_category": "Pet Products"
    },
    {
        "Category": "Manufacturing",
        "Sub_category": "Medical Devices & Equipment"
    },
    {
        "Category": "Manufacturing",
        "Sub_category": "Chemicals & Related Products"
    },
    {
        "Category": "Manufacturing",
        "Sub_category": "Pharmaceuticals"
    },
    {
        "Category": "Manufacturing",
        "Sub_category": "Appliances"
    },
    {
        "Category": "Manufacturing",
        "Sub_category": "Aerospace & Defense"
    },
    {
        "Category": "Manufacturing",
        "Sub_category": "Automotive Parts"
    },
    {
        "Category": "Manufacturing",
        "Sub_category": "Food & Beverage"
    },
    {
        "Category": "Manufacturing",
        "Sub_category": "Hand, Power & Lawn-care Tools"
    },
    {
        "Category": "Manufacturing",
        "Sub_category": "Cleaning Products"
    },
    {
        "Category": "Manufacturing",
        "Sub_category": "Boats & Submarines"
    },
    {
        "Category": "Manufacturing",
        "Sub_category": "Photographic & Optical Equipment"
    },
    {
        "Category": "Manufacturing",
        "Sub_category": "Watches & Jewelry"
    },
    {
        "Category": "Manufacturing",
        "Sub_category": "Electronics"
    },
    {
        "Category": "Manufacturing",
        "Sub_category": "Plastic, Packaging & Containers"
    },
    {
        "Category": "Manufacturing",
        "Sub_category": "Cosmetics, Beauty Supply & Personal Care Products"
    },
    {
        "Category": "Manufacturing",
        "Sub_category": "Wire & Cable"
    },
    {
        "Category": "Manufacturing",
        "Sub_category": "Glass & Clay"
    },
    {
        "Category": "Manufacturing",
        "Sub_category": "Household Goods"
    },
    {
        "Category": "Manufacturing",
        "Sub_category": "Toys & Games"
    },
    {
        "Category": "Manufacturing",
        "Sub_category": "Furniture"
    },
    {
        "Category": "Manufacturing",
        "Sub_category": "Pulp & Paper"
    },
    {
        "Category": "Manufacturing",
        "Sub_category": "Building Materials"
    },
    {
        "Category": "Manufacturing",
        "Sub_category": "Health & Nutrition Products"
    },
    {
        "Category": "Manufacturing",
        "Sub_category": "Sporting Goods"
    },
    {
        "Category": "Manufacturing",
        "Sub_category": "Telecommunication Equipment"
    },
    {
        "Category": "Media & Internet",
        "Sub_category": "Broadcasting"
    },
    {
        "Category": "Media & Internet",
        "Sub_category": "Publishing"
    },
    {
        "Category": "Media & Internet",
        "Sub_category": "Social Networks"
    },
    {
        "Category": "Media & Internet",
        "Sub_category": "Newspapers & News Services"
    },
    {
        "Category": "Media & Internet",
        "Sub_category": "Data Collection & Internet Portals"
    },
    {
        "Category": "Media & Internet",
        "Sub_category": "Ticket Sales"
    },
    {
        "Category": "Media & Internet",
        "Sub_category": "Music Production & Services"
    },
    {
        "Category": "Minerals & Mining",
        "Sub_category": "Minerals & Mining"
    },
    {
        "Category": "Organizations",
        "Sub_category": "Non-Profit & Charitable Organizations"
    },
    {
        "Category": "Organizations",
        "Sub_category": "Religious Organizations"
    },
    {
        "Category": "Organizations",
        "Sub_category": "Membership Organizations"
    },
    {
        "Category": "Real Estate",
        "Sub_category": "Real Estate"
    },
    {
        "Category": "Retail",
        "Sub_category": "Convenience Stores, Gas Stations & Liquor Stores"
    },
    {
        "Category": "Retail",
        "Sub_category": "Sporting & Recreational Equipment Retail"
    },
    {
        "Category": "Retail",
        "Sub_category": "Automobile Dealers"
    },
    {
        "Category": "Retail",
        "Sub_category": "Vitamins, Supplements & Health Stores"
    },
    {
        "Category": "Retail",
        "Sub_category": "Drug Stores & Pharmacies"
    },
    {
        "Category": "Retail",
        "Sub_category": "Department Stores, Shopping Centers & Superstores"
    },
    {
        "Category": "Retail",
        "Sub_category": "Automobile Parts Stores"
    },
    {
        "Category": "Retail",
        "Sub_category": "Furniture"
    },
    {
        "Category": "Retail",
        "Sub_category": "Record, Video & Book Stores"
    },
    {
        "Category": "Retail",
        "Sub_category": "Toys & Games"
    },
    {
        "Category": "Retail",
        "Sub_category": "Home Improvement & Hardware Retail"
    },
    {
        "Category": "Retail",
        "Sub_category": "Office Products Retail & Distribution"
    },
    {
        "Category": "Retail",
        "Sub_category": "Pet Products"
    },
    {
        "Category": "Retail",
        "Sub_category": "Jewelry & Watch Retail"
    },
    {
        "Category": "Retail",
        "Sub_category": "Grocery Retail"
    },
    {
        "Category": "Retail",
        "Sub_category": "Auctions"
    },
    {
        "Category": "Retail",
        "Sub_category": "Consumer Electronics & Computers Retail"
    },
    {
        "Category": "Retail",
        "Sub_category": "Apparel & Accessories Retail"
    },
    {
        "Category": "Retail",
        "Sub_category": "Other Rental Stores (Furniture, A/V, Construction & Industrial Equipment)"
    },
    {
        "Category": "Retail",
        "Sub_category": "Flowers, Gifts & Specialty Stores"
    },
    {
        "Category": "Software",
        "Sub_category": "Supply Chain Management (SCM) Software"
    },
    {
        "Category": "Software",
        "Sub_category": "Mobile App Development"
    },
    {
        "Category": "Software",
        "Sub_category": "Business Intelligence (BI) Software"
    },
    {
        "Category": "Software",
        "Sub_category": "Legal Software"
    },
    {
        "Category": "Software",
        "Sub_category": "Customer Relationship Management (CRM) Software"
    },
    {
        "Category": "Software",
        "Sub_category": "Engineering Software"
    },
    {
        "Category": "Software",
        "Sub_category": "Networking Software"
    },
    {
        "Category": "Software",
        "Sub_category": "Storage & System Management Software"
    },
    {
        "Category": "Software",
        "Sub_category": "Human Resources Software"
    },
    {
        "Category": "Software",
        "Sub_category": "Financial Software"
    },
    {
        "Category": "Software",
        "Sub_category": "Database & File Management Software"
    },
    {
        "Category": "Software",
        "Sub_category": "Security Software"
    },
    {
        "Category": "Software",
        "Sub_category": "Enterprise Resource Planning (ERP) Software"
    },
    {
        "Category": "Software",
        "Sub_category": "Multimedia, Games & Graphics Software"
    },
    {
        "Category": "Software",
        "Sub_category": "Content & Collaboration Software"
    },
    {
        "Category": "Software",
        "Sub_category": "Healthcare Software"
    },
    {
        "Category": "Telecommunications",
        "Sub_category": "Cable & Satellite"
    },
    {
        "Category": "Telecommunications",
        "Sub_category": "Internet Service Providers, Website Hosting & Internet-related Services"
    },
    {
        "Category": "Telecommunications",
        "Sub_category": "Telephony & Wireless"
    },
    {
        "Category": "Transportation",
        "Sub_category": "Trucking, Moving & Storage"
    },
    {
        "Category": "Transportation",
        "Sub_category": "Airlines, Airports & Air Services"
    },
    {
        "Category": "Transportation",
        "Sub_category": "Marine Shipping & Transportation"
    },
    {
        "Category": "Transportation",
        "Sub_category": "Rail, Bus & Taxi"
    },
    {
        "Category": "Transportation",
        "Sub_category": "Freight & Logistics Services"
    }
]