// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map-element {
    padding: 1.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}


.map-country-selector select {
    /* width: 100%; */
    height: 2rem;
    border: 1px none #253D97;
    /* border-radius: 8px; */
    /* background: #253D97; */
    color: #253D97;
    font-family: 'Light';
    font-size: 1rem
}

.map-country-selector select:focus {
    border: 0px none 
}

.map-country-selector select:active {
    border: 0px none 
}

.map-left-panel {
    width: 30%
}`, "",{"version":3,"sources":["webpack://./src/components/TrendingTopics/TrendingTopicsByLocation.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,aAAa;IACb,eAAe;IACf,8BAA8B;IAC9B,mBAAmB;AACvB;;;AAGA;IACI,iBAAiB;IACjB,YAAY;IACZ,wBAAwB;IACxB,wBAAwB;IACxB,yBAAyB;IACzB,cAAc;IACd,oBAAoB;IACpB;AACJ;;AAEA;IACI;AACJ;;AAEA;IACI;AACJ;;AAEA;IACI;AACJ","sourcesContent":[".map-element {\n    padding: 1.5rem;\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: space-between;\n    align-items: center;\n}\n\n\n.map-country-selector select {\n    /* width: 100%; */\n    height: 2rem;\n    border: 1px none #253D97;\n    /* border-radius: 8px; */\n    /* background: #253D97; */\n    color: #253D97;\n    font-family: 'Light';\n    font-size: 1rem\n}\n\n.map-country-selector select:focus {\n    border: 0px none \n}\n\n.map-country-selector select:active {\n    border: 0px none \n}\n\n.map-left-panel {\n    width: 30%\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
