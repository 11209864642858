import React, { useState } from "react";
import "./WorldMap.css";
import {
  ComposableMap,
  Geographies,
  Geography,
  Markers,
  Marker,
} from "react-simple-maps";

import { ReactComponent as MarkerIcon } from "../../assets/icons/map-marker.svg";

function WorldMap(props) {
  const [activeMarker, setActiveMarker] = useState(null);

  const handleMarkerHover = (markerId) => {
    setActiveMarker(markerId);
  };

  const handleMarkerLeave = () => {
    setActiveMarker(null);
  };

  const mapColor = "#034D89";
  const geographyStyle = {
    default: {
      fill: mapColor,
      stroke: "white",
      strokeWidth: 0.5,
      outline: "none",
    },
    hover: {
      fill: mapColor,
      stroke: "white",
      strokeWidth: 0.5,
      outline: "none",
    },
    pressed: {
      fill: mapColor,
      stroke: "white",
      strokeWidth: 0.5,
      outline: "none",
    },
  };

  const geoUrl = "/map.json";
  //   const geoUrl = "/world-countries-sans-antarctica.json";

  const iconOffset = -30;

  return (
    <ComposableMap
      projection="geoMercator"
      projectionConfig={{
        rotate: [0, 0, 0],
        scale: 150,
      }}
    >
      <Geographies geography={geoUrl}>
        {({ geographies }) =>
          geographies.map((geo) => (
            <Geography
              key={geo.rsmKey}
              geography={geo}
              style={geographyStyle}
            />
          ))
        }
      </Geographies>
      {props.markers.map((item) => {
        return (
          <Marker
            key={item.name}
            coordinates={item.coordinates}
            onMouseEnter={() => handleMarkerHover(item.name)}
            onMouseLeave={handleMarkerLeave}
          >
            <g transform="translate(-10, -24)">
              <MarkerIcon />
            </g>
            {activeMarker === item.name && (
              <g>
                <rect
                  x="0"
                  y="-40"
                  width={item.name.length * 8}
                  height="20"
                  //   rx="4"
                  fill="#FFF"
                  //   stroke="#000"
                  opacity="1"
                />
                <text
                  y="-25"
                  //   textAnchor="middle"
                  //   alignmentBaseline="middle"
                  style={{
                    fontFamily: "Light",
                    fontSize: "12px",
                    fill: "black",
                    zIndex: 1000,
                  }}
                >
                  {item.name}
                </text>
              </g>
            )}

            {activeMarker === item.name && <g />}
          </Marker>
        );
      })}
    </ComposableMap>
  );
}

export default WorldMap;
