export default function getLatestMondayFromDate(date) {
    const currentDayOfWeek = date.getDay();
    const diff = (currentDayOfWeek + 6) % 7; // Calculate the difference to the previous Monday
  
    const previousMonday = new Date(date); // Create a new date object to avoid mutating the original date
    previousMonday.setDate(date.getDate() - diff - 7); // Set the date to the previous Monday
  
    const year = previousMonday.getFullYear();
    const month = String(previousMonday.getMonth() + 1).padStart(2, '0');
    const day = String(previousMonday.getDate()).padStart(2, '0');
  
    return `${year}-${month}-${day}`;
  }

  