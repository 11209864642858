// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    text-align: center;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1365), 0px 0px 7px rgba(0, 0, 0, 0.132949);
    border-radius: 8px;
    background-color: white;
    font-family: 'Regular';
    z-index: 1000;
  }
  
  .error-message {
    margin-bottom: 20px;
  }
  
  .ok-button {
    background-color: red;
    border-radius: 8px;
    color: white;
    border: none;
    padding: 5px 20px;
    cursor: pointer;
  }`, "",{"version":3,"sources":["webpack://./src/components/ErrorPopup/ErrorPopup.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,aAAa;IACb,kBAAkB;IAClB,kFAAkF;IAClF,kBAAkB;IAClB,uBAAuB;IACvB,sBAAsB;IACtB,aAAa;EACf;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,qBAAqB;IACrB,kBAAkB;IAClB,YAAY;IACZ,YAAY;IACZ,iBAAiB;IACjB,eAAe;EACjB","sourcesContent":[".error-popup {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    padding: 20px;\n    text-align: center;\n    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1365), 0px 0px 7px rgba(0, 0, 0, 0.132949);\n    border-radius: 8px;\n    background-color: white;\n    font-family: 'Regular';\n    z-index: 1000;\n  }\n  \n  .error-message {\n    margin-bottom: 20px;\n  }\n  \n  .ok-button {\n    background-color: red;\n    border-radius: 8px;\n    color: white;\n    border: none;\n    padding: 5px 20px;\n    cursor: pointer;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
