import axios from 'axios'

const API_URL = 'https://industry-signals.tdsapis.com/'
const ExternalApiConnector = axios.create({
    baseURL: API_URL
})


ExternalApiConnector.interceptors.request.use(
    (config) => {
        // const token = localStorage.getItem('bearerToken');
        const token = process.env.REACT_APP_API_TOKEN

        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default ExternalApiConnector
