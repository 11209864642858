export const ScoreCalculation = (arr) => {
    if (arr.length === 0) {
        return null; // Return null if the array is empty
    }

    let max = arr[0]; // Initialize the maximum value as the first element

    for (let i = 1; i < arr.length; i++) {
        if (arr[i] > max) {
            max = arr[i]; // Update the maximum value if a larger element is found
        }
    }

    return max; // Return the maximum value
}


