// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination-element{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 35px 35px;
    gap: 20px;
}

.pagination-element button, .pagination-element span {
    font-family: 'Light';
    font-style: italic;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    border: none;
    background-color: #FAFAFA;
    color: #253D97;
}

.pagination-element button:hover {
    cursor: pointer;
    background-color: #F0F0F0;
}

.pagination-element-arrows {

    background-color: #F0F0F0;
    color : yellow
    }


.pagination-element span {
    font-family: 'Regular';
    font-style: normal;
    font-size: 12px;
    line-height: 16px;
}


`, "",{"version":3,"sources":["webpack://./src/components/PaginationElement/PaginationElement.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,iBAAiB;IACjB,SAAS;AACb;;AAEA;IACI,oBAAoB;IACpB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,YAAY;IACZ,yBAAyB;IACzB,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,yBAAyB;AAC7B;;AAEA;;IAEI,yBAAyB;IACzB;IACA;;;AAGJ;IACI,sBAAsB;IACtB,kBAAkB;IAClB,eAAe;IACf,iBAAiB;AACrB","sourcesContent":[".pagination-element{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin: 35px 35px;\n    gap: 20px;\n}\n\n.pagination-element button, .pagination-element span {\n    font-family: 'Light';\n    font-style: italic;\n    font-weight: 300;\n    font-size: 16px;\n    line-height: 22px;\n    border: none;\n    background-color: #FAFAFA;\n    color: #253D97;\n}\n\n.pagination-element button:hover {\n    cursor: pointer;\n    background-color: #F0F0F0;\n}\n\n.pagination-element-arrows {\n\n    background-color: #F0F0F0;\n    color : yellow\n    }\n\n\n.pagination-element span {\n    font-family: 'Regular';\n    font-style: normal;\n    font-size: 12px;\n    line-height: 16px;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
