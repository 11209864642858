import React, { useState } from "react";
import { Auth } from "aws-amplify";

function ForgetPasswordRecovery(props) {
  const [code, setCode] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const updatePassword = () => {
    props.onUpdatePassword({ code, newPassword });
    // Auth.forgotPasswordSubmit(props.user, code, newPassword)
    //   .then((data) => console.log(data))
    //   .catch((err) => console.log(err));
  };

  const handleBack = () => {
    props.onBack();
  };

  return (
    <div>
      <div className="forget-container">
        <img src="brand/1.png" alt="Logo" className="logo" />
        <div className="forget-container-items">
          <div className="forget-title">
            Verification has been sent to the email listed below
          </div>
          <div className="">{props.user}</div>
          <div className="forget-text">Enter verification code here</div>
          <input
            onChange={(e) => setCode(e.target.value)}
            type="text"
            placeholder="Code"
          />
          <input
            onChange={(e) => setNewPassword(e.target.value)}
            type="password"
            placeholder="New Password"
          />
          <input
            // onChange={(e) => setResendMail(e.target.value)}
            type="password"
            placeholder="New Password"
          />
          <button onClick={updatePassword}>Update Password</button>
          <div className="h-line" />
          <button className="end-buttons">Resend code</button>
          <button onClick={handleBack} className="end-buttons">
            Return to log in
          </button>
        </div>
      </div>
    </div>
  );
}

export default ForgetPasswordRecovery;
