import industryData from './IndustryMapperData.json'


const getIndustry = (industry) => {
    const industries = industryData.filter(el => el.Sub_category === industry)
    return industries
}

export default function categoriesToDB(industries) {

    const result = industries.map(element => getIndustry(element));
    // return result
    return [].concat(...result);  

}