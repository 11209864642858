import React, { useState } from "react";
import "./Filter.css";
import { InputText } from "primereact/inputtext";
import { ReactComponent as LupaIconSmall } from "../../assets/icons/icon-lupa-small.svg";
import { ReactComponent as ArrowIcon } from "../../assets/icons/icon-arrow.svg";

function Filter(props) {
  const handleCheckboxToggle = (e) => {
    props.onCheckboxChange({
      filtergroup: props.filtergroup,
      value: e.target.value,
      checked: e.target.checked,
    });
  };

  const [filterVisibility, setFilterVisibility] = useState(props.visible);

  const [data, setData] = useState(props.data);

  const [inputTextValue, setInputTextValue] = useState("");

  const dataWithChecked = data.map((item) => {
    return { ...item, checked: props.checked.includes(item.value) };
  });

  const sortedCheckboxes = [...dataWithChecked].sort((a, b) => {
    if (a.checked && !b.checked) {
      return -1; // a is checked, b is unchecked
    } else if (!a.checked && b.checked) {
      return 1; // a is unchecked, b is checked
    } else {
      return 0; // both are either checked or unchecked
    }
  });

  const handleFilterVisibility = (el) => {
    el.preventDefault();
    setFilterVisibility(!filterVisibility);
  };

  const handleInputChange = (e) => {
    setInputTextValue(e.target.value);
    const filteredData = props.data.filter((item) => {
      return item.value.toLowerCase().includes(e.target.value.toLowerCase());
    });

    setData(filteredData);
  };

  return (
    <div className="filter-element">
      <div className="filter-title-bar">
        <div className="filter-title">{props.name}</div>

        <div className="input-icon-element">
          <LupaIconSmall />

          <InputText
            className="filter-input-element"
            placeholder=""
            value={inputTextValue}
            onChange={(e) => handleInputChange(e)}
          />
        </div>
        <div
          className="filter-icon"
          onClick={(e) => handleFilterVisibility(e)}
          id={props.name}
        >
          <ArrowIcon
            style={{
              transform: filterVisibility ? "rotate(180deg)" : "rotate(0deg)",
            }}
          />
        </div>
      </div>

      <div
        className="checkbox-items"
        style={{ display: filterVisibility ? "" : "none" }}
      >
        {sortedCheckboxes.map((item, index) => {
          return (
            <label key={index}>
              <input
                type="checkbox"
                value={item.value}
                id={item.value}
                checked={item.checked}
                onChange={(e) => handleCheckboxToggle(e)}
              />
              <span>{item.label}</span>
            </label>
          );
        })}
      </div>
      <div className="horizontal-line"></div>
    </div>
  );
}

export default Filter;
