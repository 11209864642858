import React, { useState } from "react";
import { Auth } from "aws-amplify";

function ChangePasswordForm(props) {
  const [newPassword, setNewPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleChangePassword = (e) => {
    // props.onClickHandler(e);
    console.log(newPassword);
  };

  return (
    <div>
      <h2>Change Password</h2>
      <form onSubmit={handleChangePassword}>
        <div>
          <label htmlFor="currentPassword">Current Password:</label>
          {/* <input
            type="password"
            id="currentPassword"
            value={newPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
          /> */}
        </div>
        <div>
          <label htmlFor="newPassword">New Password:</label>
          <input
            type="password"
            id="newPassword"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </div>
        <button
          onClick={handleChangePassword}
          type="submit"
          disabled={isLoading}
        >
          {isLoading ? "Changing Password..." : "Change Password"}
        </button>
        {errorMessage && <p>{errorMessage}</p>}
      </form>
    </div>
  );
}

export default ChangePasswordForm;
