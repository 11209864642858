import { useState, useEffect } from 'react';

import { AmplifySignOut, withAuthenticator } from '@aws-amplify/ui-react'
import awsconfig from './aws-exports';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Amplify } from 'aws-amplify';


import { BrowserRouter, Routes, Route, Switch } from "react-router-dom";

import Search from "./pages/Search/Search";
import SavedSearch from "./pages/SavedSearch/SavedSearch";
import Data from "./pages/Data/Data";
import Admin from "./pages/Admin/Admin";
import Trending from "./pages/Trending/Trending";
import Login from "./pages/Login/Login";
import PageLayout from "./layouts/PageLayout";
import SearchResults from './pages/SearchResults/SearchResults';
import PrivateRoutes from './utils/PrivateRoutes';

import {  FiltersDataContext } from './context/Context';


Amplify.configure(awsconfig);

function App() {

  const [user, setUser] = useState(null);
  const [searchQuery, setSearchQuery] = useState(null);
  const [filtersData, setFiltersData] = useState(null);

  const isAuthenticated = true;

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    setUser(JSON.parse(storedUser));

    // remove user from local storage on beforeunload / close tab
    const handleBeforeUnload = () => {
      localStorage.removeItem('user');
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);


  return (
    <div>
 
        <FiltersDataContext.Provider value={{ filtersData, setFiltersData }}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route element={<PrivateRoutes />}>
                <Route path="/" element={<PageLayout />}>
                  <Route path="/Search" element={<Search />} />
                  <Route path="/SavedSearch" element={<SavedSearch />} />
                  <Route path="/Data" element={<Data />} />
                  <Route path="/Trending" element={<Trending />} />
                  <Route path="/Admin" element={<Admin />} />
                  <Route path="/SearchResults" element={<SearchResults />} />
                </Route>
              </Route>
            </Routes>
          </BrowserRouter>
        </FiltersDataContext.Provider>

    </div>
  );
}

// export default withAuthenticator(App);
export default App