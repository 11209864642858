export const validatePassword = (password) => {
    const numberRegex = /[0-9]/;
    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const isLengthValid = password.length >= 8;
  
    return (
      numberRegex.test(password) &&
      specialCharRegex.test(password) &&
      uppercaseRegex.test(password) &&
      lowercaseRegex.test(password) &&
      isLengthValid
    );
  };