// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn__component {
    border: 1px solid #253D97;
    border-radius: 8px;
    background: #253D97;
    height: 2.5rem;
    color: white;
    margin-right: 11px;
    font-family: 'Regular';
    font-size: calc(0.5rem + 0.5vw);
}

.btn__component:hover {
    cursor: pointer;
    background-color: #1E2749;
}`, "",{"version":3,"sources":["webpack://./src/components/Button/ButtonComponent.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,mBAAmB;IACnB,cAAc;IACd,YAAY;IACZ,kBAAkB;IAClB,sBAAsB;IACtB,+BAA+B;AACnC;;AAEA;IACI,eAAe;IACf,yBAAyB;AAC7B","sourcesContent":[".btn__component {\n    border: 1px solid #253D97;\n    border-radius: 8px;\n    background: #253D97;\n    height: 2.5rem;\n    color: white;\n    margin-right: 11px;\n    font-family: 'Regular';\n    font-size: calc(0.5rem + 0.5vw);\n}\n\n.btn__component:hover {\n    cursor: pointer;\n    background-color: #1E2749;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
