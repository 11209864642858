import React from "react";
import "./PercentageChange.css";

import { ReactComponent as UpArrowIcon } from "../../assets/icons/icon-arrowup.svg";
import { ReactComponent as DownArrowIcon } from "../../assets/icons/icon-arrowdown.svg";
import { ReactComponent as NewArrowIcon } from "../../assets/icons/icon-arrownew.svg";

function PercentageChange({ currentValue, previousValue }) {
  const percentageChange = (currentValue, previousValue) => {
    if (currentValue === 0 || previousValue === 0) {
      return 0;
    }
    return ((currentValue - previousValue) / previousValue) * 100;
  };

  const icon = (currentValue, previousValue) => {
    if (previousValue === 0) {
      return <NewArrowIcon />;
    }

    if (percentageChange(currentValue, previousValue) > 0) {
      return <UpArrowIcon />;
    } else {
      return <DownArrowIcon />;
    }
  };

  return (
    <div className="percentage_change__container">
      {icon(currentValue, previousValue)}
      {percentageChange(currentValue, previousValue).toFixed(2)}
    </div>
  );
}

export default PercentageChange;
