import React, { useState, useEffect } from "react";

import "./TrendingTopics.css";
import styles from "./TrendingTopics.module.css";

import DataTable from "../../components/TablePagination/TablePagination";
import ExternalApiEndpoints from "../ExternalApi/ExternalApiEndpoints";
import getLatestMondayFromDate from "../../utils/LatestMonday";
import getLastSunday from "../../utils/LatestSunday";
import ModalIntents from "../Modals/ModalIntents/ModalIntents";
import PercentageChange from "../PercentageChange/PercentageChange";
import SelectBoxUpdate from "../SelectBox/SelectBoxUpdate";
import { ReactComponent as HashIcon } from "../../assets/icons/icon-hash.svg";

function TrendingTopicsTabls() {
  const [data, setData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentIntent, setCurrentIntent] = useState("");
  const [categories, setCategories] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState("");
  const [latestMondayDate, setLatestMondayDate] = useState(null);

  const initialState = {
    pageSize: 10,
    pageIndex: 0,
  };

  const columns = [
    {
      Header: "Name",
      accessor: "intent",
    },
    {
      Header: "Current week score",
      accessor: "mean_score",
    },
    {
      Header: "Previous week score",
      accessor: "min_score",
    },
    {
      Header: "Change",
      accessor: "change",
    },
  ];

  const handleIndustryChange = (e) => {
    setSelectedIndustry(e.value);
  };

  const handleActiveIndustry = (intentName) => {};

  const getTopIntents = (obj) => {
    ExternalApiEndpoints.getTopIntents(obj)
      .then((res) => {
        setData(
          res.data.map((obj) => ({
            ...obj,
            intent: (
              <div
                className="span-center"
                onClick={(e) => handlerClickOnIntent(e)}
              >
                <HashIcon style={{ minWidth: "24px" }} />
                <div>{obj.intent}</div>
              </div>
            ),
            mean_score: (obj.mean_score * 100).toFixed(2),
            min_score: (obj.min_score * 100).toFixed(2),
            change: (
              <PercentageChange
                currentValue={obj.mean_score}
                previousValue={obj.min_score}
              />
            ),
          }))
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCategories = (sub_category) => {
    ExternalApiEndpoints.getCategories(sub_category)
      .then((res) => {
        setCategories(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlerClickOnIntent = (e) => {
    e.preventDefault();
    setCurrentIntent(e.target.innerText);
    getCategories(e.target.innerText);
    setIsModalOpen(true);
    console.log(e.target.innerText);
  };

  useEffect(() => {
    const currentDate = new Date();
    const latestMonday = getLatestMondayFromDate(currentDate);
    setLatestMondayDate(latestMonday);
    // console.log(latestMonday);

    ExternalApiEndpoints.getIndustries()
      .then((res) => {
        setIndustries(["All", ...res.data]);
      })
      .catch((err) => {});

    getTopIntents({
      start_date: latestMonday,
      industry: selectedIndustry === "All" ? "" : selectedIndustry,
      limit: 10,
    });
  }, [selectedIndustry]);

  return (
    <>
      {latestMondayDate && (
        <div className="trending-topics-date">
          Data generated {latestMondayDate} - {getLastSunday()}
        </div>
      )}

      <SelectBoxUpdate
        placeholder="Select Industry"
        onChange={handleIndustryChange}
        data={industries.map((industry, index) => {
          return { value: industry, label: industry };
        })}
      ></SelectBoxUpdate>

      {data.length === 0 ? (
        <div className="no-data">No data for the specified period</div>
      ) : (
        <DataTable
          tableStyle={styles.stylestable}
          trStyle={styles.stylesTr}
          tdStyle={styles.stylesTd}
          theadStyle={styles.stylesThead}
          paginationStyle={styles.stylesPagination}
          data={data}
          columns={columns}
          initialState={initialState}
        />
      )}
      <ModalIntents
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={currentIntent + " Related Topics"}
      >
        {categories.map((obj, index) => {
          return <div key={index}>{obj.topic_category}</div>;
        })}
      </ModalIntents>
    </>
  );
}

export default TrendingTopicsTabls;
