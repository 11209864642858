import React from "react";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import "./Login.css";
import { CheckboxField } from "@aws-amplify/ui-react";
import { validatePassword } from "../../utils/PasswordValidation";
import ChangePasswordForm from "../../components/ChangePassword/ChangePassword";
import ForgetPassword from "../../components/ForgetPassword/ForgetPassword";
import ForgetPasswordRecovery from "../../components/ForgetPassword/ForgetPasswordRecovery";
import ErrorPopup from "../../components/ErrorPopup/ErrorPopup";

function Login() {
  const [formState, setFormState] = React.useState("signIn");
  const [showModal, setShowModal] = React.useState(false);
  const [newPassword, setNewPassword] = React.useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = React.useState(null);
  const [userStore, setUserStore] = React.useState(null);
  const [userStorePassword, setUserStorePassword] = React.useState(null);
  const [userMail, setUserMail] = React.useState(null);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [userName, setUserName] = React.useState("");
  const [password, setPassword] = React.useState("");

  let formInputState = {
    username: "",
    password: "",
    email: "",
    verificationCode: "",
  };

  const navigate = useNavigate();
  /* onChange handler for form inputs */
  function onChange(e) {
    formInputState = { ...formInputState, [e.target.name]: e.target.value };
  }

  async function getBearerToken() {
    try {
      const session = await Auth.currentSession();
      const token = session.getAccessToken().getJwtToken();
      localStorage.setItem("bearerToken", token);
      return token;
    } catch (error) {
      console.log("Error retrieving bearer token:", error);
      return null;
    }
  }

  async function signIn() {
    const user = await Auth.signIn(
      userName,
      password
      // formInputState.username,
      // formInputState.password
    )
      .then((user) => {
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          // setUserStore(formInputState.username);
          // setUserStorePassword(formInputState.password);
          setUserStore(userName);
          setUserStorePassword(password);
          setFormState("changePassword");
        } else {
          localStorage.setItem("user", JSON.stringify(user));
          setFormState("signIn");
          getBearerToken();
          navigate("/Search");
        }
      })
      .catch((error) => {
        console.log("Error signing in:", error);
        // alert("Error signing in:", error);
        setUserName("");
        setPassword("");
        setErrorMessage(error.message);

        // Handle sign in error, such as displaying an error message to the user
      });
  }

  const handlerSetUserName = (e) => {
    setUserName(e.target.value);
  };

  const handlerSetPassword = (e) => {
    setPassword(e.target.value);
  };

  const showError = (message) => {
    setErrorMessage(message);
  };

  const closeError = () => {
    setErrorMessage(null);
  };

  const handlePasswordChange = (e) => {
    if (newPassword !== newPasswordConfirm) {
      setErrorMessage("Passwords do not match");
      setShowModal(true);
      return;
    }

    if (!validatePassword(newPassword)) {
      setErrorMessage(
        "Password must contain at least 8 characters, 1 uppercase, 1 lowercase, 1 number and 1 special character"
      );
      setShowModal(true);
      return;
    }

    const user = Auth.signIn(userStore, userStorePassword)
      .then((user) => {
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          Auth.completeNewPassword(user, newPassword, {
            given_name: "xx",
            family_name: "xx",
          });
          setUserName("");
          setPassword("");
          setFormState("signIn");
        }
      })
      .catch((error) => {});
  };

  const handlePasswordForget = () => {
    setFormState("forgetPassword");
  };

  const handlePasswordForgetCancel = () => {
    setFormState("signIn");
  };

  const handleResendMail = (e) => {
    console.log(e);
    setUserMail(e);
    setFormState("recoveryPassword");
  };
  const onUpdate = (e) => {
    console.log(e);
    Auth.forgotPasswordSubmit(userMail, e.code, e.newPassword)
      .then((data) => console.log(data))
      .catch((err) => console.log(err));

    setFormState("signIn");
  };

  return (
    <div>
      <div className="overlay">
        <ErrorPopup
          open={showModal}
          message={errorMessage}
          onClose={() => setShowModal(false)}
        />
      </div>

      {formState === "forgetPassword" && (
        <ForgetPassword
          onResendMail={handleResendMail}
          onBack={handlePasswordForgetCancel}
        />
      )}
      {formState === "recoveryPassword" && (
        <ForgetPasswordRecovery
          user={userMail}
          onUpdatePassword={onUpdate}
          onBack={handlePasswordForgetCancel}
        />
      )}

      {formState === "changePassword" && (
        <div className="changepassword-container">
          <div>
            <img src="brand/1.png" alt="Logo" className="logo" />
            <label>Please Enter a New Password </label>
            <label>New Password*</label>
            <input
              label="New Password"
              type="password"
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <label>Confirm Password*</label>
            <input
              label="Change Password"
              type="password"
              onChange={(e) => setNewPasswordConfirm(e.target.value)}
            />
            <button onClick={handlePasswordChange}>Change Password</button>
            <button onClick={() => setFormState("signIn")}>Cancel</button>
          </div>
        </div>
      )}

      {formState === "signIn" && (
        <div className="login-container">
          <div className="logo-login">
            <img src="brand/1.png" alt="Logo" className="logo" />
          </div>
          <div className="button-free-trial">
            <button id="button-free-trial-button">Start Free Trial</button>
          </div>

          <div className="login-element">
            <div className="login-title">Sign in to your account</div>

            <div className="login-row">
              <div className="login-left-element">
                <div>
                  <div className="input-label">Email</div>
                  <input
                    class="input-box"
                    name="username"
                    onChange={handlerSetUserName}
                    value={userName}
                  />
                </div>
                <div>
                  <div className="input-label">Password</div>
                  <input
                    class="input-box"
                    type="password"
                    name="password"
                    onChange={handlerSetPassword}
                    value={password}
                  />
                </div>
                <div>
                  <CheckboxField className="input-label" label="Remember me " />
                  <button onClick={signIn}>Sign In</button>
                </div>
                <div className="additional-buttons">
                  <a onClick={handlePasswordForget}>Forgot Password?</a>
                  {/* <a>
                    <svg
                      width="4"
                      height="5"
                      viewBox="0 0 4 5"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        id="."
                        d="M0.199707 2.53369C0.199707 1.89111 0.374023 1.43994 0.722656 1.18018C1.07812 0.92041 1.50537 0.790527 2.00439 0.790527C2.48975 0.790527 2.90674 0.92041 3.25537 1.18018C3.61084 1.43994 3.78857 1.89111 3.78857 2.53369C3.78857 3.14893 3.61084 3.59326 3.25537 3.8667C2.90674 4.14014 2.48975 4.27686 2.00439 4.27686C1.50537 4.27686 1.07812 4.14014 0.722656 3.8667C0.374023 3.59326 0.199707 3.14893 0.199707 2.53369Z"
                        fill="#949494"
                      />
                    </svg>
                  </a> */}
                  {/* <a>SignUp</a> */}
                </div>
              </div>
              {/* <div className="login-middle-element">or</div> */}
              {/* <div className="login-right-element">
                <button>Single Sign On (SSO)</button>
                <button>
                  <span>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_920_5810)">
                        <path
                          d="M10.2041 8.18188V12.0546H15.6957C15.4546 13.3001 14.7309 14.3547 13.6456 15.0638L16.9573 17.582C18.8868 15.8366 20 13.2729 20 10.2275C20 9.51837 19.9351 8.83648 19.8144 8.18199L10.2041 8.18188Z"
                          fill="#4285F4"
                        />
                        <path
                          d="M4.48539 11.9033L3.73848 12.4636L1.09464 14.4818C2.77368 17.7454 6.215 20 10.2039 20C12.9589 20 15.2687 19.1091 16.9571 17.5819L13.6454 15.0636C12.7363 15.6636 11.5767 16.0273 10.2039 16.0273C7.5508 16.0273 5.29668 14.2728 4.48956 11.9091L4.48539 11.9033Z"
                          fill="#34A853"
                        />
                        <path
                          d="M1.09465 5.51831C0.398954 6.86372 0.000106812 8.38193 0.000106812 10.0001C0.000106812 11.6183 0.398954 13.1365 1.09465 14.4819C1.09465 14.4909 4.48991 11.9 4.48991 11.9C4.28583 11.3 4.1652 10.6637 4.1652 9.99999C4.1652 9.33626 4.28583 8.69993 4.48991 8.09993L1.09465 5.51831Z"
                          fill="#FBBC05"
                        />
                        <path
                          d="M10.2041 3.98184C11.7069 3.98184 13.0427 4.49092 14.1095 5.47276L17.0315 2.60914C15.2597 0.990977 12.9592 0 10.2041 0C6.21521 0 2.77368 2.24546 1.09464 5.51822L4.48978 8.10005C5.29679 5.73638 7.55101 3.98184 10.2041 3.98184Z"
                          fill="#EA4335"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_920_5810">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  <span>Google</span>
                </button>
                <button>
                  <span>
                    <svg
                      width="18"
                      height="21"
                      viewBox="0 0 18 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_920_5816)">
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M18 19.25L17.9981 19.249V1.8035L11.5753 0L0.029002 4.21916L0 4.22465L0.0014242 16.8389L3.94161 15.3382V5.07495L11.5753 3.2988L11.5742 18.3885L0.00789786 16.8389L11.5742 20.9954V21L17.9981 19.2699V19.25H18Z"
                          fill="#EA3E23"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_920_5816">
                          <rect width="18" height="21" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  <span>Office 365</span>
                </button>
              </div> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Login;
