// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dmodal__position {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 2rem;
    z-index: 1000;
    border-radius: 8px;
    border: 1px solid #E2E2E2;
    box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.1);
    width: 30%;
    height: 30%;
    display: flex;
    flex-direction: column;
    font-family: 'Light';
    display: flex;
    direction: column;

}

.dmodal__title {
    font-size: 2rem;
    font-weight: bold;
    color: #0C0C0C;
}

.dmodal__content {
    display: flex;
    flex-direction: column;
    justify-items: center;
    font-size: 1rem;
    overflow-y: auto;
    color: #253D97;
    margin-top: 1rem;

}

.dmodal__buttons {
    display: flex;
    flex-grow: 1;
    align-items: flex-end;
    flex-direction: row;
    justify-content: space-around;
    justify-self: flex-end;

    
}

.dmodal__position button {
    width: 30%;
}`, "",{"version":3,"sources":["webpack://./src/components/Modals/ModalDecision/ModalDecision.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,uBAAuB;IACvB,aAAa;IACb,aAAa;IACb,kBAAkB;IAClB,yBAAyB;IACzB,8CAA8C;IAC9C,UAAU;IACV,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,oBAAoB;IACpB,aAAa;IACb,iBAAiB;;AAErB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,qBAAqB;IACrB,eAAe;IACf,gBAAgB;IAChB,cAAc;IACd,gBAAgB;;AAEpB;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,qBAAqB;IACrB,mBAAmB;IACnB,6BAA6B;IAC7B,sBAAsB;;;AAG1B;;AAEA;IACI,UAAU;AACd","sourcesContent":[".dmodal__position {\n    position: fixed;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    background-color: white;\n    padding: 2rem;\n    z-index: 1000;\n    border-radius: 8px;\n    border: 1px solid #E2E2E2;\n    box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.1);\n    width: 30%;\n    height: 30%;\n    display: flex;\n    flex-direction: column;\n    font-family: 'Light';\n    display: flex;\n    direction: column;\n\n}\n\n.dmodal__title {\n    font-size: 2rem;\n    font-weight: bold;\n    color: #0C0C0C;\n}\n\n.dmodal__content {\n    display: flex;\n    flex-direction: column;\n    justify-items: center;\n    font-size: 1rem;\n    overflow-y: auto;\n    color: #253D97;\n    margin-top: 1rem;\n\n}\n\n.dmodal__buttons {\n    display: flex;\n    flex-grow: 1;\n    align-items: flex-end;\n    flex-direction: row;\n    justify-content: space-around;\n    justify-self: flex-end;\n\n    \n}\n\n.dmodal__position button {\n    width: 30%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
