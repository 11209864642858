export default function getLastSunday() {
    const currentDate = new Date();
    const currentDayOfWeek = currentDate.getDay();
    const daysSinceLastSunday = (currentDayOfWeek + 7 - 0) % 7;
    const lastSundayDate = new Date(currentDate);
    lastSundayDate.setDate(currentDate.getDate() - daysSinceLastSunday);
      // Format the date as "yyyy-mm-dd"
    const formattedDate = lastSundayDate.toISOString().slice(0, 10);

    return formattedDate;
  }