export default function CapitalizeWords(str) {

  // Replace underscores with spaces
  var replacedStr = str.replace(/_/g, ' ');

  // Split the string into an array of words
  var words = replacedStr.split(" ");

  // Capitalize the first letter of each word
  var capitalizedWords = words.map(function(word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });

  // Join the words back into a single string
  var capitalizedStr = capitalizedWords.join(" ");

  return capitalizedStr;
}


