import React, { useEffect, useState } from "react";
import Endpoints from "../../components/Api/Endpoints";
import DataTable from "../../components/TablePagination/TablePagination";
import AddUser from "../../components/AddUser/AddUser";
import EditUser from "../../components/EditUser/EditUser";

import { ReactComponent as DeleteIcon } from "../../assets/icons/icon-delete.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/icon-arrow.svg";
import { ReactComponent as AddUserIcon } from "../../assets/icons/icon-adduser.svg";
import { ReactComponent as UsersIcon } from "../../assets/icons/icon-users.svg";

import ModalDecision from "../../components/Modals/ModalDecision/ModalDecision";

import styles from "./Admin.module.css";
import "./Admin.css";

function Admin() {
  const [data, setData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [editUser, setEditUser] = useState(false);
  const [userSub, setUserSub] = useState("");
  const [modalDecision, setModalDecision] = useState(false);
  const [userToDelete, setUserToDelete] = useState("");

  const modalState = () => {
    setIsModalOpen((prev) => !prev);
  };
  const modalOpen = () => {
    setIsModalOpen(true);
  };
  const modalClose = () => {
    setIsModalOpen(false);
    setRefresh(!refresh);
  };

  const modalEditState = () => {
    setEditUser((prev) => !prev);
  };

  const modalEditOpen = () => {
    setEditUser(true);
  };

  const modalEditClose = () => {
    setEditUser(false);
    setRefresh(!refresh);
  };

  const handleConfirm = () => {
    console.log(userToDelete);
    setModalDecision(false);
    Endpoints.deleteUser(userToDelete)
      .then((res) => {
        setRefresh(!refresh);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDelete = (e) => {
    setUserToDelete(e);
    setModalDecision(true);
  };

  const handleEdit = (e) => {
    modalEditOpen();
    setUserSub(e.sub);
  };

  const assignUser = (e) => {
    console.log(e);
    Endpoints.assignUser(userSub, e)
      .then((res) => {
        modalEditClose();
      })
      .catch((err) => {
        console.log(err);
      });
    setEditUser(false);
    setUserSub("");
  };

  // columns config
  const columns = [
    {
      Header: "Name",
      accessor: "last_name",
    },
    {
      Header: "Email Address",
      accessor: "email",
    },
    {
      Header: "Role",
      accessor: "groups",
    },
    {
      Header: "Actions",
      accessor: "actions",
    },
  ];

  // table init
  const initialState = {
    pageSize: 10,
    pageIndex: 0,
  };

  const getAllUsers = () => {
    Endpoints.getUsers()
      .then((res) => {
        setData(
          res.data.map((obj) => ({
            ...obj,
            actions: <DeleteIcon onClick={() => handleDelete(obj.email)} />,
            groups: (
              <span>
                <EditIcon
                  style={{ transform: "rotate(180deg)" }}
                  onClick={() => handleEdit(obj)}
                />
                {obj.groups}
              </span>
            ),
          }))
        );
        setLoader(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllUsers();
  }, [refresh]);

  return (
    <div className={styles.admin__page_container}>
      <ModalDecision
        open={modalDecision}
        onCancel={() => setModalDecision(false)}
        onConfirm={handleConfirm}
        title="Delete user"
        message="Are you sure to delete user?"
      />
      {editUser && <EditUser onClose={modalEditClose} onConfirm={assignUser} />}
      <div className={styles.admin__title}>Admin - List of Users</div>
      <div className="add_new_component">
        <div>
          <div>
            <UsersIcon />
          </div>
          <div>Users</div>
        </div>
        <div>
          <div className="add__user__icon">
            <AddUserIcon onClick={modalOpen} />
            {isModalOpen && <AddUser onClose={modalClose} />}
          </div>
          <div className="add__new__text">Add New</div>
        </div>
      </div>

      {data ? null : (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      )}

      <div className="user__table__container">
        <div className="newline-horizontal" />
        {loader ? (
          <DataTable
            tableStyle={styles.stylestable}
            trStyle={styles.stylesTr}
            tdStyle={styles.stylesTd}
            theadStyle={styles.stylesThead}
            paginationStyle={styles.stylesPagination}
            data={data}
            columns={columns}
            initialState={initialState}
          />
        ) : (
          <div className="loader-container">
            <div className="loader"></div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Admin;
