import { createContext } from 'react';



// export const SearchQueryContext = createContext({});

// export const SearchQueryParamsContext = createContext({});

export const FiltersDataContext = createContext({});

