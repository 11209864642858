import React from "react";
import "./ButtonComponent.css";

import { Button } from "primereact/button";

function ButtonComponent({ label, onClick }) {
  return <Button className="btn__component" label={label} onClick={onClick} />;
}

export default ButtonComponent;
