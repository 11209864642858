// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal__position {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 2rem;
    z-index: 1000;
    border-radius: 8px;
    border: 1px solid #E2E2E2;
    box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.1);
    width: 50%;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: 'Light';
    gap: 1rem;
}

.modal__title {
    font-size: 1rem;
    font-weight: bold;
    color: #0C0C0C;
}

.modal__content {
    display: flex;
    flex-direction: column;
    justify-items: center;
    gap: 1rem;
    font-size: 0.8rem;
    overflow-y: auto;
    color: #253D97;

}

.modal__overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: black;
    z-index: 1000;
}

.modal__position button {
    margin-top: 1rem;
    width: 30%;
    align-self: center;
}`, "",{"version":3,"sources":["webpack://./src/components/Modals/ModalIntents/ModalIntents.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,uBAAuB;IACvB,aAAa;IACb,aAAa;IACb,kBAAkB;IAClB,yBAAyB;IACzB,8CAA8C;IAC9C,UAAU;IACV,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,oBAAoB;IACpB,SAAS;AACb;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,qBAAqB;IACrB,SAAS;IACT,iBAAiB;IACjB,gBAAgB;IAChB,cAAc;;AAElB;;AAEA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,SAAS;IACT,uBAAuB;IACvB,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,UAAU;IACV,kBAAkB;AACtB","sourcesContent":[".modal__position {\n    position: fixed;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    background-color: white;\n    padding: 2rem;\n    z-index: 1000;\n    border-radius: 8px;\n    border: 1px solid #E2E2E2;\n    box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.1);\n    width: 50%;\n    height: 50%;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    font-family: 'Light';\n    gap: 1rem;\n}\n\n.modal__title {\n    font-size: 1rem;\n    font-weight: bold;\n    color: #0C0C0C;\n}\n\n.modal__content {\n    display: flex;\n    flex-direction: column;\n    justify-items: center;\n    gap: 1rem;\n    font-size: 0.8rem;\n    overflow-y: auto;\n    color: #253D97;\n\n}\n\n.modal__overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    bottom: 0;\n    background-color: black;\n    z-index: 1000;\n}\n\n.modal__position button {\n    margin-top: 1rem;\n    width: 30%;\n    align-self: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
