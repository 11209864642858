import React, { useRef } from "react";
import "./AddUser.css";
import Endpoints from "../Api/Endpoints";
import { ReactComponent as RemoveIcon } from "../../assets/icons/icon-remove.svg";

function AddUser({ onClose }) {
  const inputRef = useRef(null);

  const handleClose = () => {
    onClose();
  };

  const handleAddNewUser = (e) => {
    e.preventDefault();
    const inputValue = inputRef.current.value;

    Endpoints.createUser({ email: inputValue })
      .then((res) => {
        handleClose();
      })
      .catch((err) => {
        console.log(err);
      });

    // TO DO odswieyć listę userów po dodaniu nowego usera
  };

  return (
    <div className="modal-container">
      <div className="modal-content">
        <div className="modal-title">
          <div>Add New User</div>
          <div>
            <RemoveIcon onClick={handleClose} />
          </div>
          <div className="horizontal-line-modal " />
        </div>
        <div className="modal-input">
          <div className="modal-text">Email</div>
          <input ref={inputRef} type="text" placeholder="Email" />
        </div>
        <div></div>
        <div className="modal-buttons">
          <button onClick={handleClose}>Close</button>
          <button onClick={handleAddNewUser}>Add New User</button>
        </div>
      </div>
    </div>
  );
}

export default AddUser;
