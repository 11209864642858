import React, { useState, useEffect } from "react";
import "./TrendingTopicsByLocation.css";

import ExternalApiEndpoints from "../../components/ExternalApi/ExternalApiEndpoints";
import getLatestMondayFromDate from "../../utils/LatestMonday";
import WorldMap from "../../components/WorldMap/WorldMap";
import SelectBoxUpdate from "../SelectBox/SelectBoxUpdate";

import { ReactComponent as HashIcon } from "../../assets/icons/icon-hash.svg";

function TrendingTopicsByLocation() {
  const [data, setData] = useState([]);
  const [currentIntent, setCurrentIntent] = useState("");
  const [mapCountryMarkers, setMapCountryMarkers] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState("");
  const [industries, setIndustries] = useState([]);

  const handlerClickOnIntent = (e) => {
    e.preventDefault();
    setCurrentIntent(e.target.innerText);
    // handleActiveIndustry(currentIntent);
  };

  const handleActiveIndustry = (intentName) => {
    const activeCountry = data.find((obj) => obj.intent === intentName);

    const activeMarkers = activeCountry.countries.map((country) => {
      return {
        markerOffset: -30,
        name: country.country,
        coordinates: [country.longitude, country.latitude],
      };
    });
    setMapCountryMarkers(activeMarkers);
  };

  const handleIndustryChange = (e) => {
    console.log(e);
    setSelectedIndustry(e.value);
    setCurrentIntent("");
  };

  // const getTopIntents = async (obj) => {
  //   ExternalApiEndpoints.getTopIntents(obj)
  //     .then((res) => {
  //       setData(res.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  useEffect(() => {
    const currentDate = new Date();
    const latestMonday = getLatestMondayFromDate(currentDate);
    // console.log(latestMonday);

    ExternalApiEndpoints.getIndustries()
      .then((res) => {
        setIndustries(["All", ...res.data]);
      })
      .catch((err) => {});

    ExternalApiEndpoints.getTopIntents({
      start_date: latestMonday,
      industry: selectedIndustry === "All" ? "" : selectedIndustry,
      limit: 10,
    })
      .then((res) => {
        setData(res.data);
        setCurrentIntent(
          currentIntent === "" ? res.data[0].intent : currentIntent
        );

        handleActiveIndustry(currentIntent);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedIndustry, currentIntent]);

  return (
    <div className="trending-page-map">
      <div className="map-left-panel">
        {data.length === 0
          ? "No data for specified period"
          : data.map((obj, index) => (
              <div key={index}>
                {
                  <div
                    className="span-center"
                    onClick={(e) => handlerClickOnIntent(e)}
                  >
                    <HashIcon style={{ minWidth: "24px" }} />
                    <div>{obj.intent}</div>
                  </div>
                }
              </div>
            ))}
      </div>
      <div className="map-element">
        <div className="map-title">{currentIntent}</div>
        <div className="map-country-selector">
          <SelectBoxUpdate
            placeholder="Select Industry"
            onChange={handleIndustryChange}
            data={industries.map((industry, index) => {
              return { value: industry, label: industry };
            })}
          ></SelectBoxUpdate>
        </div>
        <div
          className="horizontal-line"
          style={{ "margin-bottom": "3rem" }}
        ></div>
        <WorldMap className="map-map" markers={mapCountryMarkers} />
      </div>
    </div>
  );
}

export default TrendingTopicsByLocation;
