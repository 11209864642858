import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./Search.css";
import Endpoints from "../../components/Api/Endpoints";

import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import CustomSlider from "../../components/CustomSlider/CustomSlider";
import SelectBox from "../../components/SelectBox/SelectBox";
import SubSelectBox from "../../components/SelectBox/SubSelectBox";
import ErrorPopup from "../../components/ErrorPopup/ErrorPopup";

import SearchButtons from "../../components/SearchButtons/SearchButtons";

import { ReactComponent as LupaIcon } from "../../assets/icons/icon-lupa.svg";

import { FiltersDataContext } from "../../context/Context";
import { FiltersValues } from "../../utils/FiltersValues";

import CapitalizeWords from "../../utils/CapitalizeWords";
import { getIndustryCategories } from "../../utils/IndustryMapper/IndustryMapper";
import aggregateArray from "../../utils/AggregateArray";
import getCities from "../../utils/CityMapper/CityMapper";

import { industryData } from "../../utils/Data";

function Search() {
  // data for search query
  const [searchQuery, setSearchQuery] = useState({});
  // data for filters
  const { filtersData, setFiltersData } = useContext(FiltersDataContext);
  // query params for filters
  const [filtersQuery, setFiltersQuery] = useState({
    country: [],
    city: [],
    industryCategory: [],
    industry: [],
    revenue: [],
    job_title: [],
    job_level: [],
  });

  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedIndustryCategory, setSelectedIndustryCategory] = useState("");

  // data for industries
  const uniqueIndustryCategories = [
    ...new Set(getIndustryCategories.map((item) => item.Category)),
  ];

  // radio button value - by intent by topics
  const [selectedOption, setSelectedOption] = useState("intent");

  // value in input field element
  const [searchValue, setSearchValue] = useState("");

  // open close advanced search
  const [toggleAdvancedSearchFlag, setToggleAdvancedSearchFlag] =
    useState(false);

  // modal

  const [isModalOpen, setIsModalOpen] = useState(false);

  // get data for filters
  const valuesToReturn = 100;

  const getDatatoFilters = async (params) => {
    Endpoints.getFilter(params)
      .then((res) => {
        const temp = res.data.map((item, index) => {
          return { value: item, label: item };
        });
        setFiltersData((prevData) => {
          return { ...prevData, [params.field]: temp };
        });
      })
      .catch((err) => {
        setFiltersData((prevData) => {
          return { ...prevData, [params.field]: [] };
        });
        console.log(err);
      });
  };

  useEffect(() => {
    FiltersValues.forEach((field) => {
      getDatatoFilters({
        field: field,
        size: valuesToReturn,
      });
    });
    getSubCategories();
  }, [selectedIndustryCategory]);

  // event handlers

  const handleChangeDropdown = (val) => {
    // korekta na inny format agregacji industries
    if (Object.keys(val)[0] === "industry") {
      setFiltersQuery((prevData) => {
        return { ...prevData, [Object.keys(val)[0]]: Object.values(val)[0] };
      });
      return;
    }

    setFiltersQuery((prevData) => {
      return { ...prevData, [Object.keys(val)[0]]: Object.values(val) };
    });
  };

  const toggleAdvancedSearch = () => {
    setToggleAdvancedSearchFlag(!toggleAdvancedSearchFlag);
  };

  const handleToggleRadio = () => {
    setSelectedOption(selectedOption === "intent" ? "topic" : "intent");
  };

  const navigate = useNavigate();

  const handleSearch = (event) => {
    event.preventDefault();
    // initial values for search query
    if (searchValue === "") {
      setIsModalOpen(true);
      // alert("Please enter a search query");
    } else {
      setSearchQuery({ [selectedOption]: searchValue });

      navigate(`/SearchResults`, {
        state: {
          params: filtersQuery,
          query: { [selectedOption]: searchValue },
        },
      });
    }
  };

  const handleDropdownSelectIndustry = (val) => {
    setSelectedIndustryCategory(val.industryCategory);
  };

  const getSubCategories = (value) => {
    const subCategories = getIndustryCategories
      .filter((item) => item.Category === value)
      .map((item) => {
        return { label: item.Sub_category, value: item.Database };
      });

    const subCategoriesAggregated = aggregateArray(subCategories).map(
      (item) => {
        return { label: item.label, value: item.value };
      }
    );
    return subCategoriesAggregated;
  };

  return (
    <div className="page-container">
      <ErrorPopup
        message={"Please add the search query"}
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
      <div className="center">
        <img
          className="welcome-image"
          src="/images/image-search.png"
          alt="logo"
        />
      </div>

      <div className="search-component center">
        <div className="card-text">
          <div>
            <div className="text-title">Search Intents</div>
            <div className="text-subtitle">
              Intents are a users explicit interest in a product/service
            </div>
          </div>
        </div>

        <div className="card-container">
          <div className="card-container-search">
            <div className="icon-inside-input">
              <LupaIcon className="search-icon" />
              <InputText
                className="search-input-element"
                placeholder="Search by a person or intent signals"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>

            <div className="vertical-line"></div>

            <div className="radio">
              <label>
                <input
                  type="radio"
                  value="intent"
                  checked={selectedOption === "intent"}
                  onChange={handleToggleRadio}
                />
                By Intent
              </label>
            </div>
            <div className="radio">
              <label>
                <input
                  type="radio"
                  value="topic"
                  checked={selectedOption === "topic"}
                  onChange={handleToggleRadio}
                />
                By Topics
              </label>
            </div>

            <div>
              <Button
                className="searchBottom btn-animation"
                label="Search"
                onClick={handleSearch}
              />
            </div>
          </div>
          <div
            className="h-line"
            style={{ display: toggleAdvancedSearchFlag ? "block" : "none" }}
          />
          <div
            className="card__details"
            style={{ display: toggleAdvancedSearchFlag ? "" : "none" }}
          >
            {filtersData ? (
              <div className="buttons">
                <SelectBox
                  placeholder="Country"
                  selectId="country"
                  handleChange={handleChangeDropdown}
                  data={filtersData["country"]}
                />

                <SelectBox
                  placeholder="City"
                  selectId="city"
                  handleChange={handleChangeDropdown}
                  data={
                    // filtersQuery.country.length > 0 ? filtersData["city"] : []
                    filtersQuery.country.length > 0
                      ? getCities(filtersQuery.country[0])
                      : []
                  }
                />

                <SelectBox
                  keys={"industry"}
                  selectId={"industryCategory"}
                  placeholder={"Industry"}
                  handleChange={handleDropdownSelectIndustry}
                  data={uniqueIndustryCategories.map((item) => ({
                    label: item,
                    value: item,
                  }))}
                />

                <SelectBox
                  // keys={index}
                  placeholder="Sub Industry"
                  selectId="industry"
                  handleChange={handleChangeDropdown}
                  data={
                    selectedIndustryCategory !== ""
                      ? getSubCategories(selectedIndustryCategory)
                      : []
                  }
                />
                <SelectBox
                  placeholder="Job Title"
                  selectId="job_title"
                  handleChange={handleChangeDropdown}
                  data={filtersData["job_title"]}
                />
                <SelectBox
                  placeholder="Job Level"
                  selectId="job_level"
                  handleChange={handleChangeDropdown}
                  data={filtersData["job_level"]}
                />

                {/* <label className="label">Score</label> */}
                {/* <CustomSlider className="slider" placeholder="Score" /> chwilowo wylączonyś */}
              </div>
            ) : null}
          </div>
        </div>
        <button className="btnToggle" onClick={toggleAdvancedSearch}>
          Advanced search
        </button>
      </div>
    </div>
  );
}

export default Search;
