import React from "react";
import "./SelectBox.css";
import Select from "react-select";

function SelectBox(props) {
  // const options = [
  //     { value: "blue", label: "Blue" },
  //     { value: "green", label: "Green" },
  //     { value: "orange", label: "Orange" },
  //     { value: "purple", label: "Purple" },
  // ];

  const customStyles = {
    control: (base, state) => ({
      ...base,
      color: "#253D97",
      background: "#white",
      minWidth: "127px",
      // match with the menu
      // borderRadius: state.isFocused ? "8px 8px 8px 8px" : 3,
      // Overwrittes the different states of border
      borderRadius: "8px 8px 8px 8px",
      borderColor: state.isFocused ? "#253D97" : "#253D97",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        borderColor: state.isFocused ? "red" : "#253D97",
      },
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: "#253D97",
    }),
    placeholder: (base) => ({
      ...base,
      color: "#253D97",
    }),
  };

  const handleSelectChange = (e) => {
    props.handleChange({ [props.selectId]: e.value });
  };

  return (
    <>
      <Select
        styles={customStyles}
        placeholder={props.placeholder}
        id={props.key}
        // defaultValue={colourOptions[0]}
        // isDisabled={isDisabled}
        // isLoading={isLoading}
        isClearable={false}
        // isRtl={isRtl}
        isSearchable={true}
        // name="color"
        options={props.data}
        // onChange={props.handleChange}
        onChange={handleSelectChange}
        value={props.value}
        noOptionsMessage={() => "Select filters"}
      />
    </>
  );
}

export default SelectBox;
