export default function aggregateArray(arr) {
    const result = [];
  
    arr.forEach((obj) => {
      const { label, value } = obj;
  
      const existingItem = result.find((item) => item.label === label);
      if (existingItem) {
        existingItem.value.push(value);
      } else {
        const newItem = { label, value: [value] };
        result.push(newItem);
      }
    });
  
    return result;
  }